import React, { Component } from "react";
import "./RadiusMap.style.scss";
import { Circle, Map, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import { Slider } from "rsuite";
import { Translate } from "react-localize-redux";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default class RadiusMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      center: {
        lat: this.props.lat,
        lng: this.props.lng,
      },
      zoomCalc: 12,
      radius: this.props.radius ?? 0,
    };
  }

  componentDidMount() {
    let zoomCalc = 12;
    if (this.props.radius >= 10 * 1000) {
      zoomCalc = 10;
    }
    if (this.props.radius >= 50 * 1000) {
      zoomCalc = 8;
    }
    if (this.props.radius >= 100 * 1000) {
      zoomCalc = 7;
    }
    if (this.state.radius >= 250 * 1000) {
      zoomCalc = 5;
    }
    this.setState({
      zoomCalc: zoomCalc,
      loading: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.lat) !== JSON.stringify(this.props.lat) ||
      JSON.stringify(prevProps.lng) !== JSON.stringify(this.props.lng)
    ) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.setState({
            center: {
              lat: this.props.lat,
              lng: this.props.lng,
            },
            loading: false,
          });
        }
      );
    }
    if (prevProps.radius !== this.props.radius) {
      if (!isNaN(this.props.radius)) {
        this.setState(
          {
            loading: true,
          },
          () => {
            this.setState({
              radius: this.props.radius,
              loading: false,
            });
          }
        );
      }
    }
    if (prevState.radius !== this.state.radius) {
      let zoomCalc = 12;
      if (this.state.radius >= 10 * 1000) {
        zoomCalc = 10;
      }
      if (this.state.radius >= 50 * 1000) {
        zoomCalc = 8;
      }
      if (this.state.radius >= 100 * 1000) {
        zoomCalc = 7;
      }
      if (this.state.radius >= 250 * 1000) {
        zoomCalc = 5;
      }
      this.setState({
        zoomCalc: zoomCalc,
        loading: false,
      });
    }
  }

  apiIsLoaded(map, maps) {
    const me = this;
    let circle = new maps.Circle({
      strokeColor: "#0353A4",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#0353A4",
      fillOpacity: 0.3,
      editable: true,
      draggable: false,
      map,
      center: {
        lat: this.props.lat,
        lng: this.props.lng,
      },
      radius: this.state.radius,
    });
    maps.event.addListener(circle, "radius_changed", function () {
      console.log(typeof me.props.setSearchRadius);
      if (typeof me.props.setSearchRadius === "function") {
        me.props.setSearchRadius(circle.getRadius());
      }
    });
  }

  render() {
    return (
      <>
        {typeof this.state.center.lat !== "undefined" &&
        typeof this.state.center.lng !== "undefined" ? (
          <>
            {/*<div className="radius-map">*/}
            {/*    <div style={{height: '100%', width: '100%'}}>*/}
            {/*        {this.state.loading ?*/}
            {/*            <Spinner animation="border" role="status"*/}
            {/*                     className='mx-auto d-block align-self-center'>*/}
            {/*                <span className="sr-only"><Translate id="cropField.loading"/></span>*/}
            {/*            </Spinner>*/}
            {/*            :*/}
            {/*            <GoogleMapReact*/}
            {/*                bootstrapURLKeys={{key: "AIzaSyC-YmV4eklJf4pBChz8pfSfDp6-CJhI6o0"}}*/}
            {/*                defaultCenter={this.state.center}*/}
            {/*                defaultZoom={zoom}*/}
            {/*                yesIWantToUseGoogleMapApiInternals={true}*/}
            {/*                onGoogleApiLoaded={({map, maps}) => this.apiIsLoaded(map, maps)}*/}
            {/*            >*/}
            {/*                <div*/}
            {/*                    className="marker"*/}
            {/*                    lat={this.props.lat}*/}
            {/*                    lng={this.props.lng}*/}
            {/*                    text="Home"*/}
            {/*                />*/}
            {/*            </GoogleMapReact>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            {this.state.radius !== 55000 * 1000 ? (
              <div className={"pb-3"}>
                <label
                  style={{
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: "14px",
                    display: "block",
                    cursor: "text",
                    marginBottom: "15px",
                  }}
                >
                  <Translate id="model.searchRadius" />
                </label>
                <Slider
                  progress
                  defaultValue={this.state.radius / 1000}
                  min={1}
                  max={300}
                  onChange={(value) => {
                    this.setState({ radius: value * 1000 }, () => {
                      if (typeof this.props.setSearchRadius === "function") {
                        this.props.setSearchRadius(value * 1000);
                      }
                    });
                  }}
                />
              </div>
            ) : null}
            <div className="radius-map" style={{ overflow: "hidden" }}>
              <Map
                center={this.state.center}
                zoom={this.state.zoomCalc}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                <Marker position={this.state.center}></Marker>
                <Circle center={this.state.center} radius={this.state.radius} />
              </Map>
            </div>
          </>
        ) : null}
      </>
    );
  }
}
