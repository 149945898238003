import React, { Component } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { ApiService } from "../../services/ApiService";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "./ModelProfile.style.scss";
import LazyImage from "../../components/LazyImage/LazyImage";
import { getTranslate, Translate } from "react-localize-redux";
import DisplayModelDataField from "./DisplayModelDataField";
import Icofont from "../../components/Icofont/Icofont";
import { AnimatePresence, motion } from "framer-motion";
import SimpleGallery from "../../components/SimplleGallery/SimplleGallery";
import ModelSearchActions from "../../stores/modelSearch/actions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SedCard from "../../components/SedCard/SedCard";
import ChatButton from "../../components/ChatButton/ChatButton";
import { UnitConvertService } from "../../services/UnitConvertionService";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import UpgradeField from "../../components/UpgradeField/UpgradeField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

class ModelProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      birthDate: "",
      age: "",
      ageType: "",
      mainImg: {},
      images: [],
      videos: [],
      address: {},
      loading: true,
      showDetails: false,
      showSedDownloadButton: true,
      showSedCardDownload: false,
      instaDownloadDone: false,
      showSedCardLocale:
        this.props.user.Locale !== "undefined" && this.props.user.Locale
          ? this.props.user.Locale
          : "de_DE",
    };
    this.fetchModel = this.fetchModel.bind(this);
    this.authorizedFetchModel = this.authorizedFetchModel.bind(this);
    this.toggleModelFavourites = this.toggleModelFavourites.bind(this);
  }

  componentDidMount() {
    if (
      typeof this.props.user.ClassName !== "undefined" &&
      this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer"
    ) {
      this.authorizedFetchModel();
    } else {
      this.fetchModel();
    }
  }

  fetchModel() {
    let id = this.props.match.params.id;
    if (!isNaN(id) && id) {
      ApiService.client
        .get("/TopModel/" + id)
        .then((result) => {
          this.setState(
            {
              model: result.data,
              address: result.data.Address ?? {},
              mainImg: result.data.MainImageImage ?? {},
              images: result.data.ModelImagesImage ?? [],
              videos: result.data.Videos ?? [],
              loading: false,
            },
            () => this.calcAge(result.data.Birthdate)
          );
        })
        .catch((error) => {
          let msg = this.props.translate("model.errorLoadingModel");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            msg = error.response.data.message;
          }
          this.setState({
            loading: false,
          });
          toastr.error(this.props.translate("toastr.error"), msg);
        });
    } else {
      let msg = this.props.translate("model.errorLoadingModelNoID");
      toastr.error(this.props.translate("toastr.error"), msg);
      this.setState({
        loading: false,
      });
    }
  }

  authorizedFetchModel() {
    let id = this.props.match.params.id;
    if (!isNaN(id) && id) {
      ApiService.authorizedClient(this.props.authKey)
        .get("/TopModel/" + id)
        .then((result) => {
          this.setState(
            {
              model: result.data,
              address: result.data.Address ?? {},
              mainImg: result.data.MainImageImage ?? {},
              images: result.data.ModelImagesImage ?? [],
              videos: result.data.Videos ?? [],
              loading: false,
            },
            () => this.calcAge(result.data.Birthdate)
          );
        })
        .catch((error) => {
          let msg = this.props.translate("model.errorLoadingModel");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            msg = error.response.data.message;
          }
          this.setState({
            loading: false,
          });
          toastr.error(this.props.translate("toastr.error"), msg);
        });
    } else {
      let msg = this.props.translate("model.errorLoadingModelNoID");
      toastr.error(this.props.translate("toastr.error"), msg);
      this.setState({
        loading: false,
      });
    }
  }

  getLocalUnits(seUnit) {
    if (typeof this.props.user.Locale !== "undefined") {
      if (seUnit === "cm" && this.props.user.Locale === "en_US") {
        return "inch";
      }
      if (seUnit === "kg" && this.props.user.Locale === "en_US") {
        return "lbs";
      }
      if (seUnit === "EU" && this.props.user.Locale === "en_US") {
        return "US";
      }
      if (seUnit === "mi" && this.props.user.Locale === "en_US") {
        return "km";
      }
    }
    return seUnit;
  }

  toggleModelFavourites(modelID) {
    ApiService.authorizedClient(this.props.authKey)
      .post("/TopModelList/toggleModel/", { ModelID: modelID })
      .then((r) => {
        if (typeof r.data !== "undefined") {
          let models = [];
          let newModel = this.state.model;
          let add = true;
          this.props.models.map((model) => {
            if (parseInt(model.ID) === parseInt(modelID)) {
              model.Favourit = r.data.Favourit;
              newModel = r.data;
              add = r.data.Favourit;
            }
            models.push(model);
            return null;
          });
          if (add) {
            toastr.success(
              this.props.translate("toastr.success"),
              this.props.translate("toastr.modelAdded")
            );
          } else {
            toastr.success(
              this.props.translate("toastr.success"),
              this.props.translate("toastr.modelRemoved")
            );
          }
          this.setState({ model: newModel }, () => {
            this.props.setModels(models);
          });
        }
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        this.setState({
          error: errorMsg,
          loading: false,
        });
      });
  }

  directPDFDownload() {
    if (
      document.getElementById("download-sed-card") &&
      document.getElementById("download-sed-card").children[0]
    ) {
      if (!this.state.instaDownloadDone) {
        this.setState({ instaDownloadDone: true }, () => {
          setTimeout(function () {
            document.getElementById("download-sed-card").children[0].click();
          }, 100);
        });
      }
    }
  }

  calcAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    age = age * 12 + m;
    if (age >= 24) {
      this.setState({
        age: parseInt(age / 12),
        ageType: <Translate id="model.years" />,
      });
    } else {
      this.setState({
        age: age,
        ageType: <Translate id="model.months" />,
      });
    }
  }

  render() {
    return (
      <Container>
        {this.props.user.ClassName ===
          "CIC\\TopModelCast\\TopModel\\TopModel" &&
        this.props.memberID !== parseInt(this.props.match.params.id) ? (
          <Alert variant="danger" className="mt-3">
            <Translate id="loginForm.noPermissionDesc" />
          </Alert>
        ) : this.state.loading ? (
          <Spinner
            animation="border"
            role="status"
            className="mx-auto d-block align-self-center"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <>
            <section className="py-4 pb-5">
              <Row>
                <Col xs={12} className="d-flex justify-content-center py-3">
                  {this.props.memberID ===
                  parseInt(this.props.match.params.id) ? (
                    <Button
                      as={Link}
                      to="/profile-model/"
                      className="small-btn d-flex justify-content-center flex-column"
                    >
                      <div>
                        <Icofont icon="edit" className="mr-1" />
                        <Translate id="model.editProfile" />
                      </div>
                    </Button>
                  ) : this.props.history.length > 1 ? (
                    <Button
                      onClick={this.props.history.goBack}
                      className="borderless-btn"
                    >
                      <Icofont icon="arrow-left" className="mr-2" />
                      <Translate id="model.back" />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => this.props.history.push("/")}
                      className="borderless-btn"
                    >
                      <Icofont icon="arrow-left" className="mr-2" />
                      <Translate id="model.back" />
                    </Button>
                  )}
                  {this.props.user.AboType === "IsPremiumUser" ||
                  this.props.user.ClassName ===
                    "CIC\\TopModelCast\\Customer\\Customer" ? (
                    <div id="download-sed-card">
                      {this.state.showSedCardDownload ? (
                        <PDFDownloadLink
                          className="btn btn-primary small-btn ml-2"
                          document={
                            <SedCard
                              locale={this.state.showSedCardLocale}
                              model={this.state.model}
                            />
                          }
                          fileName={
                            "SedCard-" +
                            this.state.model.BookingCode +
                            "-" +
                            Math.random()
                              .toString(36)
                              .replace(/[^a-z]+/g, "")
                              .substr(0, 8) +
                            ".pdf"
                          }
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                  className="mx-auto d-block align-self-center"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                                <Icofont
                                  icon="download"
                                  className="ml-2 mr-1"
                                />
                                <Translate id="model.downloadSedCard" />
                              </div>
                            ) : (
                              <>
                                {url ? this.directPDFDownload() : null}
                                <Icofont icon="download" className="mr-1" />
                                <Translate id="model.downloadSedCard" />
                              </>
                            )
                          }
                        </PDFDownloadLink>
                      ) : (
                        <>
                          {this.state.showSedDownloadButton ? (
                            <Button
                              variant="primary"
                              onClick={() =>
                                this.setState({ showSedCardDownload: true })
                              }
                              className="small-btn ml-2"
                            >
                              <Icofont icon="download" className="mr-1" />
                              <Translate id="model.downloadSedCard" />
                            </Button>
                          ) : null}
                        </>
                      )}
                    </div>
                  ) : (
                    <Button
                      as={Link}
                      to="/upgrade"
                      className="small-btn d-flex justify-content-center flex-column ml-3"
                    >
                      <div>
                        <Icofont icon="download" className="mr-1" />
                        <Translate id="model.downloadSedCard" />
                      </div>
                    </Button>
                  )}
                </Col>
              </Row>
              {this.props.user.AboType === "IsFreeUser" && (
                <Row>
                  <Col xs={12}>
                    <UpgradeField />
                  </Col>
                </Row>
              )}
            </section>
            <section className="model-profile">
              <Row>
                <Col xs={12} className="text-center pb-5">
                  <h1 className="model-title">
                    {this.state.model.FirstName} ({this.state.age}{" "}
                    {this.state.ageType})
                  </h1>
                  <p className="booking-code">{this.state.model.BookingCode}</p>
                  <p className="model-address">
                    {this.state.model.AddressTitle}
                  </p>
                </Col>
                <Col xs={12} className="pb-5">
                  <LazyImage src={this.state.model.PreviewImage} fluid />
                </Col>
              </Row>
              <Row className="main-data">
                <Col
                  lg={4}
                  className="d-flex justify-content-between flex-lg-column align-items-center"
                >
                  <label>
                    <Translate id="model.height" />
                  </label>
                  <DisplayModelDataField
                    value={this.state.model.Height}
                    unit={this.getLocalUnits("cm")}
                  />
                </Col>
                <Col
                  lg={4}
                  className="d-flex justify-content-between flex-lg-column align-items-center middle-data"
                >
                  <label>
                    <Translate id="model.weight" />
                  </label>
                  <DisplayModelDataField
                    value={this.state.model.Weight}
                    unit={this.getLocalUnits("kg")}
                  />
                </Col>
                <Col
                  lg={4}
                  className="d-flex justify-content-between flex-lg-column align-items-center"
                >
                  <label>
                    <Translate id="model.cost" />
                  </label>
                  <DisplayModelDataField
                    value={this.state.model.Price}
                    unit={this.state.model.PriceType + "/h"}
                  />
                </Col>
              </Row>
            </section>
            {this.state.model.ShoeSize ||
            this.state.model.Chest ||
            this.state.model.Waist ||
            this.state.model.Hip ||
            this.state.model.ClothingSize ||
            this.state.model.SkinColor ||
            this.state.model.SkinType ||
            this.state.model.HairColor ||
            this.state.model.EyeColor ? (
              <section className="model-details">
                <AnimatePresence>
                  {!this.state.showDetails ? (
                    <motion.div
                      key={1}
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      className="overflow-hidden"
                      transition={{ duration: 0.5 }}
                    >
                      <div
                        className="details-toggle"
                        onClick={() => this.setState({ showDetails: true })}
                      >
                        <Translate id="model.showDetails" />{" "}
                        <Icofont icon="arrow-down" />
                      </div>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.showDetails ? (
                    <motion.div
                      key={2}
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      className="overflow-hidden"
                      transition={{ duration: 0.5 }}
                    >
                      <Row>
                        <Col xs={12}>
                          <hr />
                        </Col>
                        {this.state.model.ShoeSize ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.shoesize" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={this.state.model.ShoeSize}
                                  gender={this.state.model.Gender}
                                  unitType={"Shoe"}
                                  unit={this.getLocalUnits("EU")}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.Chest ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.chest" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={this.state.model.Chest}
                                  unit={this.getLocalUnits("cm")}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.Waist ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.waist" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={this.state.model.Waist}
                                  unit={this.getLocalUnits("cm")}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.Hip ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.hip" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={this.state.model.Hip}
                                  unit={this.getLocalUnits("cm")}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.ClothingSize ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.clothingSize" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={this.state.model.ClothingSize}
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.SkinColor ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.skincolor" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={
                                    <Translate
                                      id={
                                        "skincolor.sc_" +
                                        this.state.model.SkinColor
                                      }
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.SkinType ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.skintype" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={
                                    <Translate
                                      id={
                                        "skintype.st_" +
                                        this.state.model.SkinType
                                      }
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.EyeColor ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.eyes" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={
                                    <Translate
                                      id={
                                        "color.c_" + this.state.model.EyeColor
                                      }
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.Langauges ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.languages" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                {JSON.parse(this.state.model.Langauges).map(
                                  (langcode, index) => {
                                    let lang =
                                      UnitConvertService.langauges.find(
                                        (elem) => elem.value === langcode
                                      );
                                    let count = JSON.parse(
                                      this.state.model.Langauges
                                    ).length;
                                    if (lang) {
                                      return (
                                        <span key={index}>
                                          {lang.label + (count > 1 ? ", " : "")}
                                        </span>
                                      );
                                    }
                                    return (
                                      <span key={index}>
                                        {langcode + (count > 1 ? ", " : "")}
                                      </span>
                                    );
                                  }
                                )}
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        {this.state.model.HairColor ? (
                          <Col lg={6}>
                            <Row className="details-row py-lg-4 py-3">
                              <Col xs={6}>
                                <label>
                                  <Translate id="model.hair" />:
                                </label>
                              </Col>
                              <Col xs={6} className="text-right">
                                <DisplayModelDataField
                                  value={
                                    <Translate
                                      id={
                                        "color.c_" + this.state.model.HairColor
                                      }
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        ) : null}
                        <Col xs={12}>
                          <hr />
                        </Col>
                      </Row>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.showDetails ? (
                    <motion.div
                      key={3}
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      className="overflow-hidden"
                      transition={{ duration: 0.5 }}
                    >
                      <div
                        className="details-toggle"
                        onClick={() => this.setState({ showDetails: false })}
                      >
                        <Translate id="model.hideDetails" />{" "}
                        <Icofont icon="arrow-up" />
                      </div>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </section>
            ) : null}
            {(this.props.user.AboType === "IsPremiumUser" ||
              this.props.user.ClassName ===
                "CIC\\TopModelCast\\Customer\\Customer") &&
            (this.state.model.SocialFacebook ||
              this.state.model.SocialInstagram ||
              this.state.model.SocialTiktok ||
              this.state.model.SocialSnapchat ||
              this.state.model.SocialYoutube ||
              this.state.model.SocialX) ? (
              <section className="model-social">
                <h5 className="text-center">
                  <Translate id="model.socialheadline" />
                </h5>
                <Row className="text-center">
                  {this.state.model.SocialFacebook && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialFacebook }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icofont icon="icofont-facebook" />
                      </Link>
                    </Col>
                  )}
                  {this.state.model.SocialInstagram && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialInstagram }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icofont icon="icofont-instagram" />
                      </Link>
                    </Col>
                  )}
                  {this.state.model.SocialTiktok && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialTiktok }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faTiktok} />
                      </Link>
                    </Col>
                  )}
                  {this.state.model.SocialSnapchat && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialSnapchat }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icofont icon="icofont-snapchat" />
                      </Link>
                    </Col>
                  )}
                  {this.state.model.SocialX && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialX }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faXTwitter} />
                      </Link>
                    </Col>
                  )}
                  {this.state.model.SocialYoutube && (
                    <Col>
                      <Link
                        to={{ pathname: this.state.model.SocialYoutube }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </Link>
                    </Col>
                  )}
                </Row>
              </section>
            ) : null}
            {this.state.model.Desc ? (
              <section className="model-desc">
                <h5 className="text-center">
                  <Translate id="model.desc" />
                </h5>
                <p>{this.state.model.Desc}</p>
              </section>
            ) : null}
            <section className="model-images pb-5">
              {this.props.user.AboType === "IsPremiumUser" ? (
                <SimpleGallery images={this.state.images} limit={99} />
              ) : (
                <SimpleGallery images={this.state.images} limit={2} />
              )}
            </section>
            {this.state.videos.length ? (
              <section className="model-images pb-5">
                <h4>
                  <Translate id="model.videos" />
                </h4>
                <Row>
                  {this.state.videos.map((video, index) => {
                    return (
                      <Col lg={6} key={video.ID}>
                        <Player>
                          <source src={video.AbsoluteURL} />
                        </Player>
                        <a
                          className="w-100 text-center d-block py-2"
                          href={video.AbsoluteURL}
                          download={true}
                        >
                          <Translate id="model.download" />{" "}
                          <Icofont icon="download" />
                        </a>
                        {/*<video width="100%" controls>*/}
                        {/*    <source src={video.AbsoluteURL} type={video.MimeType}/>*/}
                        {/*</video>*/}
                      </Col>
                    );
                  })}
                </Row>
              </section>
            ) : null}
            {this.props.user.ClassName ===
            "CIC\\TopModelCast\\Customer\\Customer" ? (
              <section className="model-actions py-5 d-flex align-items-center justify-content-center">
                <div className="model-action d-flex flex-column justify-content-center">
                  <ChatButton
                    modelID={this.state.model.ID}
                    modelBookingCode={this.state.model.BookingCode}
                    fallbackCall={() =>
                      this.props.history.push(
                        "/chats/new/" + this.state.model.BookingCode
                      )
                    }
                  >
                    <div className="text-center">
                      <Icofont icon="ui-text-chat" />
                    </div>
                    <div className="action-title">
                      <Translate id="modelSearch.startChat" />
                    </div>
                  </ChatButton>
                </div>
                <div
                  className={
                    "model-action d-flex flex-column justify-content-center " +
                    (this.state.model.Favourit ? "active-favorit" : "")
                  }
                  onClick={() =>
                    this.toggleModelFavourites(this.props.match.params.id)
                  }
                >
                  {this.state.model.Favourit ? (
                    <>
                      <div className="text-center text-danger">
                        <Icofont icon="ui-rate-remove" />
                      </div>
                      <div className="action-title text-danger">
                        <Translate id="modelSearch.remove" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <Icofont icon="favourite" />
                      </div>
                      <div className="action-title">
                        <Translate id="modelSearch.addToFavourites" />
                      </div>
                    </>
                  )}
                </div>
              </section>
            ) : null}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  memberID: state.user.memberID,
  authKey: state.user.authKey,
  user: state.user.user,
  models: state.modelSearch.models,
  translate: getTranslate(state.localize),
});
const mapDispatchToProps = (dispatch) => ({
  setModels: (models) => dispatch(ModelSearchActions.setModels(models)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelProfile));
