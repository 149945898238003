import React, { Component } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "CustomChart.js Line CustomChart",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];
//
// export const data = {
//   labels,
//   datasets: [
//     // {
//     //   label: "Dataset 1",
//     //   data: [1, 2, 3, 4, 5, 6],
//     //   borderColor: "rgb(255, 99, 132)",
//     //   backgroundColor: "rgba(255, 99, 132, 0.5)",
//     // },
//     {
//       label: "",
//       data: [1, 7, 3, 4, 3, 6],
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };

class CustomChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  }

  componentDidMount() {
    this.setState({
      chartData: {
        labels: this.props.chartLabels,
        datasets: [
          {
            label: "Impressionen via Suche",
            data: this.props.chartSearchData,
            borderColor: "rgb(171,133,39)",
            backgroundColor: "rgba(171,133,39, 0.5)",
          },
          {
            label: "Profil-Impressionen",
            data: this.props.chartProfileData,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
    });
  }

  render() {
    return (
      <Row className="my-3">
        <Col>
          {this.state.chartData && (
            <Line
              data={this.state.chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: this.props.chartTitle,
                  },
                },
                showTooltips: true,
                tooltipEvents: ["mousemove", "touchstart", "touchmove"],
              }}
            />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  memberID: state.user.memberID,
  authKey: state.user.authKey,
  user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(CustomChart));
