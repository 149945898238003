import React, { Component } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { ApiService } from "../../services/ApiService";
import { Link } from "react-router-dom";
import { HTMLService } from "../../services/HTMLService";
import { getTranslate, Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import LocationField from "../LocationField/LocationField";

class RegsiterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      companyWebsite: "",
      address: {},
      addressError: "",
      addressTitle: "",
      firstName: "",
      firstNameError: "",
      surname: "",
      surnameError: "",
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      passwordRepeat: "",
      passwordRepeatError: "",
      loading: false,
      error: "",
      birthDate: "",
      done: false,
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleSurnameChange = this.handleSurnameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordRepeatChange =
      this.handlePasswordRepeatChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.thankYouContent = this.thankYouContent.bind(this);
    this.setAddress = this.setAddress.bind(this);
  }

  componentDidMount() {
    // TODO GTAG
    // window.gtag("event", "conversion", {
    //   send_to: "AW-461926465/bF1ECIGz3u4YEMHgodwB",
    // });
  }

  setAddress(address) {
    let addressError = "";
    if (
      address.City === "" ||
      address.Street === "" ||
      address.Country === "" ||
      address.PostalCode === "" ||
      address.Number === ""
    ) {
      addressError = <Translate id="model.addressError" />;
    }
    this.setState({
      address: address,
      addressError: addressError,
    });
  }

  handleBirthdayChange(date) {
    this.setState({ birthDate: date });
  }

  handleFirstNameChange(e) {
    let text = e.target.value;
    this.setState({ firstName: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({
        firstNameError: <Translate id="error.wrongFirstName" />,
      });
    } else {
      this.setState({ firstNameError: "" });
    }
  }

  handleSurnameChange(e) {
    let text = e.target.value;
    this.setState({ surname: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({ surnameError: <Translate id="error.wrongSurname" /> });
    } else {
      this.setState({ surnameError: "" });
    }
  }

  handleEmailChange(e) {
    let text = e.target.value;
    this.setState({ email: text });
    let emailValidation = text.match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    if (emailValidation === null) {
      this.setState({ emailError: <Translate id="error.emailError" /> });
    } else {
      this.setState({ emailError: "" });
    }
  }

  handlePasswordChange(e) {
    let text = e.target.value;
    this.setState({ password: text });
    if (text.length < 8) {
      this.setState({ passwordError: <Translate id="error.passwordLength" /> });
    } else {
      this.setState({ passwordError: "" });
    }
  }

  handlePasswordRepeatChange(e) {
    let text = e.target.value;
    this.setState({ passwordRepeat: text });
    if (text !== this.state.password) {
      this.setState({
        passwordRepeatError: <Translate id="error.passwordRepeat" />,
      });
    } else {
      this.setState({ passwordRepeatError: "" });
    }
  }

  handleSubmit() {
    if (
      this.state.firstNameError ||
      this.state.surnameError ||
      this.state.emailError ||
      this.state.passwordError ||
      this.state.passwordRepeatError ||
      this.state.addressError
    ) {
      this.setState({ error: <Translate id="error.checkInput" /> });
      return;
    }
    if (
      this.state.firstName.length <= 0 ||
      this.state.surname.length <= 0 ||
      this.state.email.length <= 0 ||
      this.state.password.length <= 0 ||
      this.state.passwordRepeat.length <= 0 ||
      this.state.company.length <= 0 ||
      this.state.companyWebsite.length <= 0 ||
      typeof this.state.address.Title === "undefined"
    ) {
      this.setState({ error: <Translate id="error.enterAllFields" /> });
      return;
    }

    const me = this;
    me.setState({ loading: true });
    let memberClass = "TopModel";
    if (me.props.type === "Scout") {
      memberClass = "Customer";
    }
    if (me.props.type === "Model") {
      memberClass = "TopModel";
    }

    // TODO GTAG
    // window.gtag("event", "conversion", {
    //   send_to: "AW-461926465/bF1ECIGz3u4YEMHgodwB",
    // });
    ApiService.client
      .post("/" + memberClass, {
        Company: this.state.company,
        CompanyWebsite: this.state.companyWebsite,
        Surname: me.state.surname,
        FirstName: me.state.firstName,
        Email: me.state.email,
        Status: memberClass === "TopModel" ? "Active" : "Registered",
        Locale: "de_DE",
        // Locale: me.props.locale ?? "de_DE",
      })
      .then(
        (response) => {
          ApiService.client
            .put("/Member/" + response.data.ID, {
              Password: me.state.password,
            })
            .then(
              (response) => {
                if (memberClass !== "Customer") {
                  this.setState({ done: true });
                } else {
                  this.saveCustomerAddress(response.data.ID);
                }
              },
              (error) => {
                let errorMsg = this.props.translate("error.unknown");
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  errorMsg = error.response.data.message;
                }
                me.setState({ loading: false, error: errorMsg, page: 1 });
              }
            );
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          me.setState({ loading: false, error: errorMsg, page: 1 });
        }
      );
  }

  saveCustomerAddress(scoutID) {
    let data = this.state.address;
    data.MemberID = scoutID;
    ApiService.client.post("Address/", data).then(
      (response) => {
        ApiService.client
          .put("Customer/" + scoutID, {
            AddressID: response.data.ID,
          })
          .then(
            () => {
              this.setState({ done: true });
            },
            (error) => {
              let errorMsg = this.props.translate("error.unknown");
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                errorMsg = error.response.data.message;
              }
              toastr.error(this.props.translate("toastr.error"), errorMsg);
            }
          );
      },
      (error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      }
    );
  }

  thankYouContent() {
    if (this.props.type === "Model") {
      return (
        <Col md={12} className="text-center">
          <h1 className="pb-4 alternative-font font-size-big">
            <Translate id="registerForm.super" />
          </h1>
          <p className="pb-5">
            <Translate id="registerForm.thankYouModel" />
          </p>
          <Button as={Link} to="/" disabled={this.state.loading}>
            <Translate id="registerForm.loginNow" />
          </Button>
        </Col>
      );
    } else {
      return (
        <Col md={12} className="text-center">
          <h1 className="pb-4 alternative-font font-size-big">
            <Translate id="registerForm.perfect" />
          </h1>
          <p className="pb-5">
            <Translate id="registerForm.thankYouScout" />
          </p>
        </Col>
      );
    }
  }

  render() {
    return (
      <div className="full-height-center d-flex align-items-center">
        <section className="section pt-5 pb-5 w-100">
          <Container>
            <Row>
              {this.state.done ? (
                this.thankYouContent()
              ) : (
                <>
                  <Col
                    xs={12}
                    className="pb-5 header-padding"
                    dangerouslySetInnerHTML={{
                      __html: HTMLService.parseVideo(this.props.homeText),
                    }}
                  />
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputFirstName">
                        <Translate id="register.firstName" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputFirstName"
                        onChange={this.handleFirstNameChange}
                        disabled={this.state.loading}
                        value={this.state.firstName}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.firstNameError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputLastName">
                        <Translate id="register.surname" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputLastName"
                        onChange={this.handleSurnameChange}
                        disabled={this.state.loading}
                        value={this.state.surname}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.surnameError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputEmail">
                        <Translate id="register.email" />*
                      </Form.Label>
                      <Form.Control
                        type="email"
                        id="inputEmail"
                        onChange={this.handleEmailChange}
                        disabled={this.state.loading}
                        value={this.state.email}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">{this.state.emailError}</p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputFirstName">
                        <Translate id="register.password" />*
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="inputPassword"
                        onChange={this.handlePasswordChange}
                        disabled={this.state.loading}
                        value={this.state.password}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.passwordError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputFirstName">
                        <Translate id="register.passwordRepeat" />*
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="inputPasswordRepeat"
                        onChange={this.handlePasswordRepeatChange}
                        disabled={this.state.loading}
                        value={this.state.passwordRepeat}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.passwordRepeatError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputCompany">
                        <Translate id="register.company" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputCompany"
                        onChange={(e) =>
                          this.setState({ company: e.target.value })
                        }
                        disabled={this.state.loading}
                        value={this.state.company}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.companyError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group className="from-label-group">
                      <Form.Label htmlFor="inputCompanyWebsite">
                        <Translate id="register.companyWebsite" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputCompanyWebsite"
                        onChange={(e) =>
                          this.setState({ companyWebsite: e.target.value })
                        }
                        disabled={this.state.loading}
                        value={this.state.companyWebsite}
                        className="input-foreground"
                      />
                      <p className="text-danger m-0">
                        {this.state.companyError}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group
                      className={
                        "from-label-group " + this.state.addressShowRequired
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="register.companyAddress" />*
                      </Form.Label>
                      <LocationField
                        setAddress={(address) => this.setAddress(address)}
                        address={this.state.addressTitle}
                      />
                      {!this.state.addressError ? (
                        <p className="text-muted m-0">
                          <Translate id="model.addressError" />
                        </p>
                      ) : (
                        <p className="text-danger m-0">
                          {this.state.addressError}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    {this.state.error ? (
                      <Alert variant="danger">{this.state.error}</Alert>
                    ) : null}
                    <Button
                      onClick={this.handleSubmit}
                      disabled={this.state.loading}
                      className="mb-2 w-100"
                    >
                      <Translate id="registerForm.finishRegistration" />
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locale.locale,
  translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, null)(withLocalize(RegsiterForm));
