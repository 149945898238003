import React from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Col, Form } from "react-bootstrap";
import { ApiService } from "../../services/ApiService";
import { connect } from "react-redux";
import UserActions from "../../stores/user/actions";
import "./Login.style.scss";
import { getTranslate, Translate } from "react-localize-redux";
import { toastr } from "react-redux-toastr";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isPasswordRestMode: false,
      password: "",
      loading: false,
      error: "",
      errors: [],
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordRestSubmit = this.handlePasswordRestSubmit.bind(this);
  }

  handlePasswordChange(e) {
    let text = e.target.value;
    this.setState({ password: text });
  }

  onKeyDownMain = (event) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleEmailChange(e) {
    let text = e.target.value;
    this.setState({ username: text });
  }

  handlePasswordRestSubmit() {
    const me = this;
    if (!me.state.username) {
      me.setState({
        loading: false,
        error: <Translate id="login.enterEmail" />,
      });
      return;
    }
    me.setState({ loading: true, error: "" });
    ApiService.client
      .get("/Member/sendResetEmail?email=" + this.state.username)
      .then((response) => {
        me.setState({
          loading: false,
          error: "",
          username: "",
          isPasswordRestMode: false,
        });
        toastr.success(
          this.props.translate("toastr.success"),
          this.props.translate("toastr.passwordMail")
        );
      })
      .catch((error) => {
        let msg = <Translate id="error.unknown" />;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          msg = error.response.data.message;
        }
        me.setState({ loading: false, error: msg });
      });
  }

  handleSubmit() {
    const me = this;
    me.setState({ loading: true });

    ApiService.clientLocalized(this.props.locale)
      .post("Member/authorize", {
        Email: me.state.username,
        Password: me.state.password,
      })
      .then(
        (response) => {
          if (response.data.valid === false) {
            me.setState({ loading: false, errors: response.data.messages });
          } else {
            me.props.login(
              response.data.Key,
              response.data.ValidUntil,
              response.data.MemberID
            );

            if (typeof me.props.url !== "undefined" && me.props.url.length) {
              this.props.history.push(me.props.url);
            } else {
              if (response.data.IsModel === true) {
                this.props.history.push("/model/" + response.data.MemberID);
              } else {
                this.props.history.push("/");
              }
            }
          }
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages[0].message
          ) {
            errorMsg = error.response.data.messages[0].message;
          }
          me.setState({ loading: false, error: errorMsg });
        }
      );
  }

  render() {
    return (
      <div className="login-form-holder">
        <Col xs={12} className="text-center">
          {this.props.noPermission ? (
            <>
              <h1 className="pb-4">
                <Translate id="loginForm.noPermission" />
              </h1>
              <p className="pb-4">
                <Translate id="loginForm.noPermissionDesc" />
              </p>
            </>
          ) : (
            <>
              <h1 className="pb-4">
                <Translate id="loginForm.header" />
              </h1>
              <p className="pb-4">
                <Translate id="loginForm.desc" />
              </p>
            </>
          )}
        </Col>
        <Form>
          <div className="form-group">
            <Translate>
              {({ translate }) => (
                <Form.Control
                  type="email"
                  id="inputEmail"
                  placeholder={translate("login.mailPlaceholder")}
                  onChange={this.handleEmailChange}
                  value={this.state.username}
                  disabled={this.state.loading}
                  className="input-foreground"
                />
              )}
            </Translate>
          </div>
          {!this.state.isPasswordRestMode ? (
            <div className="form-group">
              <Translate>
                {({ translate }) => (
                  <Form.Control
                    type="password"
                    id="inputPassword"
                    placeholder={translate("login.passwordPlaceholder")}
                    onChange={this.handlePasswordChange}
                    disabled={this.state.loading}
                    value={this.state.password}
                    onKeyDown={this.onKeyDownMain}
                    className="input-foreground"
                  />
                )}
              </Translate>
            </div>
          ) : null}
          {this.state.error ? (
            <Alert variant="danger">{this.state.error}</Alert>
          ) : null}
          {this.state.isPasswordRestMode ? (
            <Button
              onClick={this.handlePasswordRestSubmit}
              className="mb-2 w-100"
            >
              <Translate id="login.resetPassword" />
            </Button>
          ) : (
            <Button onClick={this.handleSubmit} className="mb-2 w-100">
              <Translate id="login.login" />
            </Button>
          )}
          {this.state.isPasswordRestMode ? (
            <div className="text-center pt-3">
              <span
                className="text-primary cursor-pointer"
                onClick={() =>
                  this.setState({
                    isPasswordRestMode: !this.state.isPasswordRestMode,
                  })
                }
              >
                <b>
                  <Translate id="login.switchLogin" />
                </b>
              </span>
            </div>
          ) : (
            <>
              <div className="text-center pt-3">
                <span
                  className="text-primary cursor-pointer"
                  onClick={() =>
                    this.setState({
                      isPasswordRestMode: !this.state.isPasswordRestMode,
                    })
                  }
                >
                  <b>
                    <Translate id="login.switchPW" />
                  </b>
                </span>
              </div>
            </>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  translate: getTranslate(state.localize),
  locale: state.locale.locale,
});

const mapDispatchToProps = (dispatch) => ({
  login: (authKey, validUntil, memberID) =>
    dispatch(UserActions.login(authKey, validUntil, memberID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
