import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getTranslate, Translate } from "react-localize-redux";
import UserActions from "../../stores/user/actions";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "./StatisticPage.style.scss";
import { toastr } from "react-redux-toastr";
import { ApiService } from "../../services/ApiService";
import CustomChart from "../../components/CustomChart/CustomChart";
import statPreview from "../../assets/img/ads/stats.png";

class StatisticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartLabels: [],
      chartDataSearch: [],
      chartDataProfile: [],
    };

    this.fetchStats = this.fetchStats.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();

    if (this.props.memberID) {
      this.fetchStats();
    } else {
      this.props.history.push("/");
    }

    this.setState({
      memberID: this.props.memberID,
      loading: false,
    });
  }

  fetchStats() {
    if (this.props.user.AboType === "IsPremiumUser") {
      ApiService.authorizedClient(this.props.authKey)
        .get("TopModelStatistic/" + this.props.memberID + "/customChartData")
        .then((response) => {
          // console.log(response.data);
          this.setState({
            chartLabels: response.data.Labels.reverse(),
            chartDataSearch: response.data.FoundInSearch.reverse(),
            chartDataProfile: response.data.ProfileViews.reverse(),
          });
        })
        .catch((error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          // toastr.error(this.props.translate("toastr.error"), errorMsg);
        });
    }
  }

  render() {
    return (
      <Container>
        <section className="py-4">
          {this.state.loading ? (
            <Spinner
              animation="border"
              role="status"
              className="mx-auto my-3 d-block align-self-center"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <Container>
              <Row>
                <Col>
                  <h1 className="my-3 text-center pb-5">
                    <Translate id="statisticPage.title" />
                  </h1>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col>
                  {this.props.user.AboType === "IsFreeUser" ? (
                    <Row>
                      <Col>
                        <h4 className="my-3 text-center">
                          <Link to="/upgrade">
                            <Translate id="statisticPage.titleUpgrade" />
                          </Link>
                        </h4>
                        <img
                          alt="StatsPreview"
                          className="img-fluid"
                          src={statPreview}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col>
                          {this.state.chartLabels.length > 0 ? (
                            <CustomChart
                              chartSearchData={this.state.chartDataSearch}
                              chartProfileData={this.state.chartDataProfile}
                              chartLabels={this.state.chartLabels.slice(
                                this.state.chartLabels.length - 7,
                                this.state.chartLabels.length
                              )}
                              chartTitle="in den letzten 7 Tagen"
                            />
                          ) : (
                            <Row>
                              <Col className="text-center">
                                <Translate id="statisticPage.dataTomorrow" />
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {this.state.chartLabels.length > 0 && (
                            <CustomChart
                              chartSearchData={this.state.chartDataSearch}
                              chartProfileData={this.state.chartDataProfile}
                              chartLabels={this.state.chartLabels}
                              chartTitle="in den letzten 31 Tagen"
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.user,
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  userIsLoading: state.user.userIsLoading,
  userErrorMessage: state.user.userErrorMessage,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StatisticPage));
