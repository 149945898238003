import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import Icofont from "../Icofont/Icofont";
import "./UpgradeCard.style.scss";

export default class UpgradeCard extends Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = { activeCard: 1 };
  }

  executeScroll = () => {
    const scrollTarget = document.getElementById("payment-page");
    scrollTarget.scrollIntoView();
  };

  handleClick = (event) => {
    let list = event.currentTarget.classList;
    const token = "is-active";
    const matches = document.querySelectorAll("div.card");

    matches.forEach((card) => {
      if (card.classList.contains(token)) {
        card.classList.remove(token);
      }
    });

    if (!list.contains(token)) {
      list.add(token);
    }

    this.executeScroll();
  };

  render() {
    return (
      <div className="cardWrapper">
        {this.props.isPremium ? (
          <div onClick={this.handleClick} className="primeCard card">
            <div className="card-title">
              <Translate id="upgrade.premiumTitle" />
            </div>
            <div className="content">
              <Translate id="upgrade.premiumDesc" />
            </div>
            <ul>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList1" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList2" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList3" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList9" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList4" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList5" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList6" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList7" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.premiumList8" />
              </li>
            </ul>
            <div className="upgradeBtn">
              <Translate id="upgrade.upgradeNow" />
            </div>
          </div>
        ) : (
          <div
            onClick={this.handleClick}
            className={"basicCard card is-active"}
          >
            <div className="card-title">
              <Translate id="upgrade.basicTitle" />
            </div>
            <div className="content">
              <Translate id="upgrade.basicDesc" />
            </div>
            <ul>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.basicList1" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.basicList2" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.basicList3" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.basicList5" />
              </li>
              <li>
                <Icofont icon="icofont-check-circled" />
                <Translate id="upgrade.basicList4" />
              </li>
              {/*<li>*/}
              {/*  <Icofont icon="icofont-close-line-circled" />*/}
              {/*  <Translate id="upgrade.basicList6" />*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Icofont icon="icofont-close-line-circled" />*/}
              {/*  <Translate id="upgrade.basicList7" />*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Icofont icon="icofont-check-circled" />*/}
              {/*  <Translate id="upgrade.basicList8" />*/}
              {/*</li>*/}
            </ul>
            <div className="currentAbo">
              <Icofont icon="icofont-check" />
              <Translate id="upgrade.currentAbo" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
