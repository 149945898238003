import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Image, Navbar } from "react-bootstrap";
// import Logo from "../../assets/img/logo_navi_topmodelcast_worldwide-min.png";
import Logo from "../../assets/img/logo_newstar.png";
import UserActions from "../../stores/user/actions";
import { connect } from "react-redux";
import "./Header.style.scss";
import Icofont from "../Icofont/Icofont";
import ModelSearchActions from "../../stores/modelSearch/actions";
import { Translate } from "react-localize-redux";
import { ApiService } from "../../services/ApiService";
import LocaleActions from "../../stores/locale/actions";
import queryString from "query-string";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noHeader: false,
      menuOpen: false,
      activeNav: "",
      chatCount: 0,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      let params = queryString.parse(this.props.location.search);
      if (params.noHeader) {
        this.setState({
          noHeader: true,
        });
      }
    }

    let pathArray = this.props.history.location.pathname.split("/");
    if (typeof pathArray[1] !== "undefined") {
      if (pathArray[1] !== this.state.activeNav) {
        this.setState({
          activeNav: pathArray[1],
        });
      }
    }

    if (
      this.props.isLoggedIn &&
      (this.props.user.ClassName === "CIC\\TopModelCast\\TopModel\\TopModel" ||
        this.props.user.ClassName ===
          "CIC\\TopModelCast\\Customer\\Customer") &&
      this.props.user.Status === "Active"
    ) {
      this.fetchChatCount();
      this.chatinterval = setInterval(() => {
        this.fetchChatCount();
      }, 10000);
    }
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params.noHeader) {
      this.setState({
        noHeader: true,
      });
    }
    let pathArray = this.props.history.location.pathname.split("/");
    if (typeof pathArray[1] !== "undefined") {
      if (pathArray[1] !== this.state.activeNav) {
        this.setState({
          activeNav: pathArray[1],
        });
      }
    }
    if (
      this.props.isLoggedIn &&
      (this.props.user.ClassName === "CIC\\TopModelCast\\TopModel\\TopModel" ||
        this.props.user.ClassName === "CIC\\TopModelCast\\Customer\\Customer")
    ) {
      this.fetchChatCount();
      this.chatinterval = setInterval(() => {
        this.fetchChatCount();
      }, 10000);
    }
  }

  componentWillUnmount() {}

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  fetchChatCount() {
    if (this.props.isLoggedIn) {
      ApiService.authorizedClient(this.props.authKey)
        .get("/Chat/unreadChatsCount")
        .then((response) => {
          if (this.state.chatCount !== response.data.Count) {
            this.setState({ chatCount: response.data.Count });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div ref={(node) => (this.node = node)}>
        <Navbar
          color="light"
          expand="xs"
          className={
            "navbar-light px-0" +
            (this.props.white ? "force-white" : "") +
            " " +
            (this.state.noHeader ? "d-none" : "")
          }
        >
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">
                <Image
                  src={Logo}
                  className="img-fluid"
                  onClick={() => this.setState({ showMobile: false })}
                  style={{ maxHeight: "60px" }}
                />
              </Link>
            </Navbar.Brand>
            <div className="nav-icons d-flex align-items-center">
              {this.props.isLoggedIn ? (
                <>
                  {this.props.user.ClassName ===
                    "CIC\\TopModelCast\\TopModel\\TopModel" &&
                  this.props.user.Status === "Active" ? (
                    <Link
                      to={"/model/" + this.props.memberID}
                      className={
                        "nav-icon " +
                        (this.state.activeNav === "model" ||
                        this.state.activeNav === "profile-model"
                          ? "active"
                          : "")
                      }
                    >
                      <Icofont icon="user" />
                      <span>
                        <Translate id="header.myProfile" />
                      </span>
                    </Link>
                  ) : this.props.user.ClassName ===
                    "CIC\\TopModelCast\\Customer\\Customer" ? (
                    <>
                      <Link
                        to="/profile-scout/"
                        className={
                          "nav-icon " +
                          (this.state.activeNav === "profile-scout"
                            ? "active"
                            : "")
                        }
                      >
                        <Icofont icon="user" />
                        <span>
                          <Translate id="header.myProfile" />
                        </span>
                      </Link>
                      <Link
                        to="/model-search"
                        className={
                          "nav-icon " +
                          (this.state.activeNav === "model-search"
                            ? "active"
                            : "")
                        }
                      >
                        <Icofont icon="penalty-card" />
                        <span>
                          <Translate id="header.findModel" />
                        </span>
                      </Link>
                      <Link
                        to="/favoriten"
                        className={
                          "nav-icon " +
                          (this.state.activeNav === "favoriten" ? "active" : "")
                        }
                      >
                        <Icofont icon="favourite" />
                        <span>
                          <Translate id="header.favorite" />
                        </span>
                      </Link>
                    </>
                  ) : null}
                  {this.props.user.AdminPermission ? (
                    <a
                      href={process.env.REACT_APP_BASE_URL + "/admin/"}
                      target="_blank"
                      className="nav-icon"
                    >
                      <Icofont icon="gears" />
                      <span>Admin</span>
                    </a>
                  ) : (
                    <>
                      {this.props.user.Status === "Active" ? (
                        <>
                          <Link
                            to="/chats"
                            className={
                              "nav-icon " +
                              (this.state.activeNav === "chats" ? "active" : "")
                            }
                          >
                            <Icofont icon="chat" />
                            <span>
                              <Translate id="header.chats" />
                            </span>
                            {this.state.chatCount ? (
                              <div className="chat-counter">
                                {this.state.chatCount}
                              </div>
                            ) : null}
                          </Link>
                          {this.props.user.ClassName ===
                            "CIC\\TopModelCast\\TopModel\\TopModel" && (
                            <Link
                              to={"/stats/" + this.props.memberID}
                              className={
                                "nav-icon " +
                                (this.state.activeNav === "stats"
                                  ? "active"
                                  : "")
                              }
                            >
                              <Icofont icon="chart-histogram" />
                              <span>
                                <Translate id="header.stats" />
                              </span>
                            </Link>
                          )}
                          {this.props.user.AboType === "IsFreeUser" && (
                            <Link
                              to={"/upgrade/"}
                              className={
                                "nav-icon " +
                                (this.state.activeNav === "upgrade"
                                  ? "active"
                                  : "")
                              }
                            >
                              <Icofont icon="icofont-star" />
                              <span>
                                <Translate id="header.upgrade" />
                              </span>
                            </Link>
                          )}
                          <Link
                            to="/settings"
                            className={
                              "nav-icon " +
                              (this.state.activeNav === "settings"
                                ? "active"
                                : "")
                            }
                          >
                            <Icofont icon="settings" />
                            <span>
                              <Translate id="header.settings" />
                            </span>
                          </Link>
                        </>
                      ) : null}
                    </>
                  )}
                  <Link
                    to="/"
                    className="nav-icon cursor-pointer"
                    onClick={() => {
                      this.props.logout();
                      this.props.setSetByUser(false);
                      clearInterval(this.chatinterval);
                      this.props.clearModelSearch();
                      this.props.setShowSlider(true);
                    }}
                  >
                    <Icofont icon="logout" />
                    <span>
                      <Translate id="header.logout" />
                    </span>
                  </Link>
                </>
              ) : null}
              <Link to="/support" className="nav-icon">
                <Icofont icon="question-circle" />
                <span>
                  <Translate id="header.support" />
                </span>
              </Link>
              {/*<div className="d-inline-block ml-3">*/}
              {/*  <LanguageSwitcher />*/}
              {/*</div>*/}
            </div>
          </Container>
        </Navbar>
        {this.state.noHeader ? <div className="pb-5 pt-5" /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  memberID: state.user.memberID,
  authKey: state.user.authKey,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
  clearModelSearch: () => dispatch(ModelSearchActions.clearModelSearch()),
  setShowSlider: (mode) => dispatch(ModelSearchActions.setShowSlider(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
