import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import { Link, withRouter } from "react-router-dom";
import UserActions from "../../stores/user/actions";
import LocaleActions from "../../stores/locale/actions";
import ModelSearchActions from "../../stores/modelSearch/actions";
import { connect } from "react-redux";

class PaymentComplete extends Component {
  render() {
    return (
      <Container>
        <div className="full-height-center d-flex align-items-center">
          <section className="w-100">
            <h3>
              <Translate id="payment.completeTitle" />
            </h3>
            <p>
              <Translate id="payment.completeDesc" />
            </p>
            <Link
              to="/"
              className="nav-icon cursor-pointer"
              onClick={() => {
                this.props.logout();
                this.props.setSetByUser(false);
                clearInterval(this.chatinterval);
                this.props.clearModelSearch();
                this.props.setShowSlider(true);
              }}
            >
              <p className="pb-5">
                <Button className="w-100">
                  <Translate id="payment.completeBtn" />
                </Button>
              </p>
            </Link>
          </section>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  memberID: state.user.memberID,
  authKey: state.user.authKey,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
  clearModelSearch: () => dispatch(ModelSearchActions.clearModelSearch()),
  setShowSlider: (mode) => dispatch(ModelSearchActions.setShowSlider(mode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentComplete));
