import React from "react";
import { Col, Row } from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class SimpleGallery extends React.Component {
  static defaultProps = {
    images: [],
    limit: 0,
  };

  state = {
    resizedImages: [],
    lightBoxOpen: false,
    imgIndex: 0,
  };

  openLightBoxAtImg(imgIndex) {
    this.setState({
      lightBoxOpen: !this.state.lightBoxOpen,
      imgIndex: imgIndex,
    });
  }

  render() {
    return (
      <Row>
        {this.props.images.slice(0, this.props.limit).map((img, index) => {
          return (
            <Col xs={6} md={4} key={img.ID} className="mb-3">
              <div className="bg-white d-flex align-items-center h-100 shadow">
                <LazyImage
                  className="cursor-pointer"
                  fluid
                  src={img.ImageURL ?? img.AbsoluteURL}
                  onClick={() => this.openLightBoxAtImg(index)}
                />
              </div>
            </Col>
          );
        })}
        {this.state.lightBoxOpen && (
          <Lightbox
            mainSrc={
              this.props.images[this.state.imgIndex].ImageURL ??
              this.props.images[this.state.imgIndex].AbsoluteURL
            }
            nextSrc={
              this.props.images[
                (this.state.imgIndex + 1) % this.props.images.length
              ].ImageURL ??
              this.props.images[
                (this.state.imgIndex + 1) % this.props.images.length
              ].AbsoluteURL
            }
            prevSrc={
              this.props.images[
                (this.state.imgIndex + this.props.images.length - 1) %
                  this.props.images.length
              ].ImageURL ??
              this.props.images[
                (this.state.imgIndex + this.props.images.length - 1) %
                  this.props.images.length
              ].AbsoluteURL
            }
            onCloseRequest={() => this.setState({ lightBoxOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                imgIndex:
                  (this.state.imgIndex + this.props.images.length - 1) %
                  this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                imgIndex: (this.state.imgIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </Row>
    );
  }
}

export default SimpleGallery;
