import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import { connect } from "react-redux";
import { ApiService } from "../../services/ApiService";
import { toastr } from "react-redux-toastr";
import { getTranslate, Translate } from "react-localize-redux";
import Icofont from "../../components/Icofont/Icofont";
import LocaleActions from "../../stores/locale/actions";
import { withRouter } from "react-router-dom";
import UpgradeField from "../../components/UpgradeField/UpgradeField";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberID: 0,
      firstName: "",
      surname: "",
      email: "",
      firstNameError: "",
      surnameError: "",
      emailError: "",
      password: "",
      passwordError: "",
      passwordRepeat: "",
      passwordRepeatError: "",
      loading: true,
      errorMain: "",
      succesMain: "",
      errorPWD: "",
      succesPWD: "",
      confirmDelete: false,
      aboType: "",
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleSurnameChange = this.handleSurnameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMainDataSubmit = this.handleMainDataSubmit.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordRepeatChange =
      this.handlePasswordRepeatChange.bind(this);
    this.disableModel = this.disableModel.bind(this);
    this.disableCustomer = this.disableCustomer.bind(this);
  }

  disableModel() {
    ApiService.authorizedClient(this.props.authKey)
      .put("TopModel/" + this.props.memberID, {
        Status: "DisabledByUser",
      })
      .then(
        () => {
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("settings.accountDisabled")
          );
          this.props.logout();
          this.props.setSetByUser(false);
          this.props.history.push("/");
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  disableCustomer() {
    ApiService.authorizedClient(this.props.authKey)
      .put("Customer/" + this.props.memberID, {
        Status: "DisabledByUser",
      })
      .then(
        () => {
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("settings.accountDisabled")
          );
          this.props.logout();
          this.props.setSetByUser(false);
          this.props.history.push("/");
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  handleFirstNameChange(e) {
    let text = e.target.value;
    this.setState({ firstName: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({
        firstNameError: <Translate id="error.wrongFirstName" />,
      });
    } else {
      this.setState({ firstNameError: "" });
    }
    this.setState({ succesMain: "" });
  }

  handleSurnameChange(e) {
    let text = e.target.value;
    this.setState({ surname: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({ surnameError: <Translate id="error.wrongSurname" /> });
    } else {
      this.setState({ surnameError: "" });
    }
    this.setState({ succesMain: "" });
  }

  handleEmailChange(e) {
    let text = e.target.value;
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

    if (!re.test(text)) {
      this.setState({ emailError: <Translate id="error.emailError" /> });
    } else {
      this.setState({ emailError: "" });
    }
    this.setState({ succesMain: "" });
  }

  onKeyDownMain = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleMainDataSubmit();
    }
  };

  onKeyDownPWD = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handlePasswordSubmit();
    }
  };

  handleMainDataSubmit() {
    if (
      this.state.firstNameError ||
      this.state.surnameError ||
      this.state.emailError
    ) {
      this.setState({ errorMain: <Translate id="error.checkInput" /> });
      return;
    }
    if (
      this.state.firstName.length <= 0 ||
      this.state.surname.length <= 0 ||
      this.state.email.length <= 0
    ) {
      this.setState({ errorMain: <Translate id="error.enterAllFields" /> });
      return;
    }

    const me = this;
    me.setState({ loading: true });
    ApiService.authorizedClient(this.props.authKey)
      .put("/Member/" + this.state.memberID, {
        FirstName: me.state.firstName,
        Surname: me.state.surname,
        Email: me.state.email,
      })
      .then(
        (response) => {
          me.setState({ loading: false, errorMain: "", page: 2 });
          this.props.fetchUser();
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("settings.settingsSaved")
          );
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          me.setState({ loading: false });
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  handlePasswordChange(e) {
    let text = e.target.value;
    this.setState({ password: text });
    if (text.length < 8) {
      this.setState({ passwordError: <Translate id="error.passwordLength" /> });
    } else {
      this.setState({ passwordError: "" });
    }
  }

  handlePasswordRepeatChange(e) {
    let text = e.target.value;
    this.setState({ passwordRepeat: text });
    if (text !== this.state.password) {
      this.setState({
        passwordRepeatError: <Translate id="error.passwordRepeat" />,
      });
    } else {
      this.setState({ passwordRepeatError: "" });
    }
  }

  handlePasswordSubmit() {
    if (this.state.passwordError || this.state.passwordRepeatError) {
      this.setState({ errorPWD: <Translate id="error.checkInput" /> });
      return;
    }
    if (
      this.state.password.length <= 0 ||
      this.state.passwordRepeat.length <= 0
    ) {
      this.setState({ errorPWD: <Translate id="error.enterAllFields" /> });
      return;
    }

    const me = this;
    me.setState({ loading: true });
    ApiService.authorizedClient(this.props.authKey)
      .put("/Member/" + this.state.memberID, {
        Password: me.state.password,
      })
      .then(
        () => {
          me.setState({ loading: false, errorPWD: "", page: 2 });
          this.props.fetchUser();
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("settings.passwordSaved")
          );
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          me.setState({ loading: false });
          this.setState({ errorPWD: "" });
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  componentDidMount() {
    this.props.fetchUser();
    this.setState({
      memberID: this.props.memberID,
      firstName: this.props.user.FirstName,
      surname: this.props.user.Surname,
      email: this.props.user.Email,
      aboType: this.props.user.AboType,
      loading: false,
    });
  }

  render() {
    return (
      <Container>
        <section className="py-4">
          {this.state.loading ? (
            <Spinner
              animation="border"
              role="status"
              className="mx-auto my-3 d-block align-self-center"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              <div className="text-center">
                <h1 className="pb-4">
                  <Translate id="settings.header" />
                </h1>
              </div>
              {this.props.user.InvoiceLink ||
              this.props.user.ContractDisplayLink ? (
                <div className="py-3 d-sm-flex">
                  {this.props.user.InvoiceLink ? (
                    <>
                      <a
                        className="btn btn-primary small-btn mr-2 mb-2"
                        href={this.props.user.InvoiceLink}
                        target="_blank"
                      >
                        <Icofont icon="eye" className="mr-2 " />{" "}
                        <Translate id="settings.invoice" />
                      </a>
                      <br />
                    </>
                  ) : null}
                  {this.props.user.ContractDisplayLink ? (
                    <>
                      <a
                        className="btn btn-primary small-btn  mb-2"
                        href={this.props.user.ContractDisplayLink}
                        target="_blank"
                      >
                        <Icofont icon="eye" className="mr-2" />
                        <Translate id="settings.contract" />
                      </a>
                      <br />
                    </>
                  ) : null}
                </div>
              ) : null}
              <h4 className="mt-0 mb-4">
                <Translate id="settings.contactData" />
              </h4>
              <Form>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputFirstName">
                    <Translate id="settings.firstName" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputFirstName"
                    onChange={this.handleFirstNameChange}
                    disabled={this.state.loading}
                    value={this.state.firstName}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.firstNameError}</p>
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputLastName">
                    <Translate id="settings.surname" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputLastName"
                    onChange={this.handleSurnameChange}
                    disabled={this.state.loading}
                    value={this.state.surname}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.firstNameError}</p>
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputEmail">
                    <Translate id="settings.email" />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    id="inputEmail"
                    onChange={this.handleEmailChange}
                    disabled={this.state.loading}
                    value={this.state.email}
                    onKeyDown={this.onKeyDownMain}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.emailError}</p>
                </Form.Group>
                {this.state.errorMain ? (
                  <Alert variant="danger" className="mt-3">
                    {this.state.errorMain}
                  </Alert>
                ) : null}
                <Button onClick={this.handleMainDataSubmit} className="w-100">
                  <Translate id="settings.save" />
                </Button>
              </Form>
              {this.state.aboType === "IsFreeUser" ? (
                <UpgradeField></UpgradeField>
              ) : null}
              <div className="border-bottom my-5" />
              <h4 className="mb-4">
                <Translate id="settings.changePassword" />
              </h4>
              <Form>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputEmail">
                    <Translate id="settings.password" />
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="inputPassword"
                    onChange={this.handlePasswordChange}
                    disabled={this.state.loading}
                    value={this.state.password}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.passwordError}</p>
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputEmail">
                    <Translate id="settings.passwordRepeat" />
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="inputPasswordRepeat"
                    onChange={this.handlePasswordRepeatChange}
                    disabled={this.state.loading}
                    value={this.state.passwordRepeat}
                    className="input-foreground"
                    onKeyDown={this.onKeyDownPWD}
                  />
                  <p className="text-danger m-0">
                    {this.state.passwordRepeatError}
                  </p>
                </Form.Group>
                {this.state.errorPWD ? (
                  <Alert variant="danger" className="mt-3">
                    {this.state.errorPWD}
                  </Alert>
                ) : null}
                <Button onClick={this.handlePasswordSubmit} className="w-100">
                  <Translate id="settings.savePassword" />
                </Button>
              </Form>
              {this.props.user.ClassName ===
              "CIC\\TopModelCast\\TopModel\\TopModel" ? (
                <Row>
                  <Col lg={12} className="py-4 text-center">
                    {this.state.confirmDelete ? (
                      <div className="confirm-delete">
                        <p>
                          <Translate id="model.deleteAccountConfirm" />
                        </p>
                        <small>
                          <Translate id="model.deleteAccountDisclaimer" />
                        </small>
                        <div className="buttons">
                          <Icofont
                            icon="check-alt"
                            className="text-success mr-2"
                            onClick={this.disableModel}
                          />
                          <Icofont
                            icon="close-line"
                            className="text-danger mr-2"
                            onClick={() =>
                              this.setState({
                                confirmDelete: !this.state.confirmDelete,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <Button
                        variant="danger"
                        onClick={() => this.setState({ confirmDelete: true })}
                      >
                        <Translate id="model.deleteAccount" />
                      </Button>
                    )}
                  </Col>
                </Row>
              ) : null}
              {this.props.user.ClassName ===
              "CIC\\TopModelCast\\Customer\\Customer" ? (
                <Row>
                  <Col lg={12} className="py-4 text-center">
                    {this.state.confirmDelete ? (
                      <div className="confirm-delete">
                        <p>
                          <Translate id="customer.deleteAccountConfirm" />
                        </p>
                        <div className="buttons">
                          <Icofont
                            icon="check-alt"
                            className="text-success mr-2"
                            onClick={this.disableCustomer}
                          />
                          <Icofont
                            icon="close-line"
                            className="text-danger mr-2"
                            onClick={() =>
                              this.setState({
                                confirmDelete: !this.state.confirmDelete,
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <Button
                        variant="danger"
                        onClick={() => this.setState({ confirmDelete: true })}
                      >
                        <Translate id="customer.deleteAccount" />
                      </Button>
                    )}
                  </Col>
                </Row>
              ) : null}
            </>
          )}
        </section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.user,
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  userIsLoading: state.user.userIsLoading,
  userErrorMessage: state.user.userErrorMessage,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
  fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
