import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import NotFound from "../NotFound/NotFound";
import ReduxToastr from "react-redux-toastr";
import Header from "../../components/Header/Header";
import ModelRegister from "../../components/Register/ModelRegister";
import ScoutRegister from "../../components/Register/ScoutRegister";
import { AnimatePresence, motion } from "framer-motion";
import EditModelProfile from "../EditModelProfile/EditModelProfile";
import ModelProfile from "../ModelProfile/ModelProfile";
import ModelSearch from "../ModelSearch/ModelSearch";
import Settings from "../Settings/Settings";
import Chats from "../Chats/Chats";
import { connect } from "react-redux";
import ChatWindow from "../Chats/ChatWindow";
import { Container } from "react-bootstrap";
import Login from "../../components/Login/Login";
import EditScoutProfile from "../EditScoutProfile/EditScoutProfile";
import Favourites from "../Favourites/Favourites";
import ApproveModelProfile from "../ModelProfile/ApproveModelProfile";
import ContactTree from "../ContactTree/ContactTree";
import ScrollToTop from "../../components/ScrollTop/ScrollTop";
import QuickLoginModel from "../../components/QuickLogin/QuickLoginModel";
import Payment from "../Payment/Payment";
import PaymentComplete from "../Payment/PaymentComplete";
import SedCardExaplePage from "../SedCardExaplePage/SedCardExaplePage";
import PasswordReset from "../../components/PasswordReset/PasswordReset";
import AdminLogin from "../../components/QuickLogin/AdminLogin";
import QuickLogout from "../../components/QuickLogin/QuickLogout";
import SharedList from "../Favourites/SharedList";
import QuickSedcardLoginModel from "../../components/QuickLogin/QuickSedcardLoginModel";
import UpgradePage from "../UpgradePage/UpgradePage";
import StatisticPage from "../Statistics/StatisticPage";

const pageVariants = {
  initial: {
    x: "200%",
  },
  in: {
    x: 0,
  },
  out: {
    x: "-200%",
  },
};

class Wrapper extends Component {
  render() {
    return (
      <>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <Header />
        <AnimatePresence exitBeforeEnter={true}>
          <ScrollToTop>
            <Switch
              location={this.props.location}
              key={this.props.location.pathname}
            >
              <Route exact path="/">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {typeof this.props.user !== "undefined" &&
                  this.props.user.ClassName ===
                    "CIC\\TopModelCast\\Customer\\Customer" ? (
                    <ModelSearch />
                  ) : (
                    <Home />
                  )}
                </motion.div>
              </Route>
              <Route exact path="/register-model">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <ModelRegister />
                </motion.div>
              </Route>
              <Route exact path="/register-scout">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <ScoutRegister />
                </motion.div>
              </Route>
              <Route exact path="/modelapproval/:id">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {this.props.isLoggedIn ? (
                    this.props.user.AdminPermission ? (
                      <ApproveModelProfile adminMode={true} />
                    ) : (
                      <Container>
                        <div className="pt-5" />
                        <Login
                          noPermission={true}
                          url={this.props.location.pathname}
                        />
                      </Container>
                    )
                  ) : (
                    <Container>
                      <div className="pt-5" />
                      <Login
                        noPermission={true}
                        url={this.props.location.pathname}
                      />
                    </Container>
                  )}
                </motion.div>
              </Route>
              <Route exact path="/model/:id">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {this.props.isLoggedIn ? (
                    <ModelProfile />
                  ) : (
                    <Container>
                      <div className="pt-5" />
                      <Login
                        noPermission={true}
                        url={this.props.location.pathname}
                      />
                    </Container>
                  )}
                </motion.div>
              </Route>
              <Route exact path="/profile-model">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <EditModelProfile />
                </motion.div>
              </Route>
              <Route exact path="/pw/:resetCode/">
                <PasswordReset />
              </Route>
              <Route exact path="/aql/:hash/">
                <AdminLogin />
              </Route>
              <Route exact path="/qlm/:hash/">
                <QuickLoginModel />
              </Route>
              <Route path="/login/">
                <QuickLogout />
              </Route>
              <Route exact path="/qlm/:hash/:url">
                <QuickLoginModel />
              </Route>
              <Route exact path="/scqlm/:hash/">
                <QuickSedcardLoginModel />
              </Route>
              <Route exact path="/profile-scout">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <EditScoutProfile />
                </motion.div>
              </Route>
              <Route exact path="/model-search">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {this.props.isLoggedIn &&
                  this.props.user !== "undefined" &&
                  this.props.user.ClassName ===
                    "CIC\\TopModelCast\\Customer\\Customer" ? (
                    <ModelSearch />
                  ) : (
                    <Container>
                      <div className="pt-5" />
                      <Login
                        noPermission={true}
                        url={this.props.location.pathname}
                      />
                    </Container>
                  )}
                </motion.div>
              </Route>
              <Route exact path="/settings">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Settings />
                </motion.div>
              </Route>
              <Route exact path="/upgrade">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <UpgradePage />
                </motion.div>
              </Route>
              {this.props.user.ClassName ===
                "CIC\\TopModelCast\\TopModel\\TopModel" && (
                <Route exact path="/stats/:id">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <StatisticPage />
                  </motion.div>
                </Route>
              )}
              <Route exact path="/chats">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Chats />
                </motion.div>
              </Route>
              <Route exact path="/chats/:id/:bookingCode">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <ChatWindow />
                </motion.div>
              </Route>
              <Route exact path="/chats/:id/">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <ChatWindow />
                </motion.div>
              </Route>
              <Route exact path="/favoriten">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {this.props.isLoggedIn &&
                  this.props.user !== "undefined" &&
                  this.props.user.ClassName ===
                    "CIC\\TopModelCast\\Customer\\Customer" ? (
                    <Favourites />
                  ) : (
                    <Container>
                      <div className="pt-5" />
                      <Login
                        noPermission={true}
                        url={this.props.location.pathname}
                      />
                    </Container>
                  )}
                </motion.div>
              </Route>
              <Route exact path="/list/:hash">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <SharedList />
                </motion.div>
              </Route>
              <Route exact path="/support">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <ContactTree />
                </motion.div>
              </Route>
              <Route exact path="/payment">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Payment />
                </motion.div>
              </Route>
              <Route exact path="/sedcard">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <SedCardExaplePage />
                </motion.div>
              </Route>
              <Route exact path="/pc">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <PaymentComplete />
                </motion.div>
              </Route>
              <Route default>
                <NotFound />
              </Route>
            </Switch>
          </ScrollToTop>
        </AnimatePresence>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.user,
});

export default connect(mapStateToProps, null)(Wrapper);
