import React, { Component } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ApiService } from "../../services/ApiService";
import { getTranslate, Translate } from "react-localize-redux";
import axios from "axios";
import UserActions from "../../stores/user/actions";
import { toastr } from "react-redux-toastr";
import { HTMLService } from "../../services/HTMLService";

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePayment: "",
      showPaymentSite: true,
      countryPaymentAmount: 0,
      stripePaymentAmount: 0,
      paymentURL: "",
      paymentHTML: "",
      paymentScript: "",
      loading: true,
      modalOpen: false,
      showPaymentData: false,
      showSofort: true,
      paymentError: "",
      error: "",
      prePaymentText: "",
      mainPaymentText: "",
      postPaymentText: "",
    };
    this.fetchLatestCurrencies = this.fetchLatestCurrencies.bind(this);
    this.convertCurrency = this.convertCurrency.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser();
    this.renderOnSitePayment();

    if (
      this.props.memberID &&
      this.props.user.ClassName === "CIC\\TopModelCast\\TopModel\\TopModel"
    ) {
      this.setState({
        stripePaymentAmount: this.props.user.StripePaymentAmount,
      });
      if (
        this.props.user.PriceType &&
        this.props.user.StripePaymentAmount &&
        this.props.user.PriceType !== "EUR"
      ) {
        this.fetchLatestCurrencies(
          "EUR",
          this.props.user.PriceType,
          this.props.user.StripePaymentAmount
        );
      }

      // TODO HIER SOLLTE EIGENTLICH MITTELS FALSE VERHINDERT WERDEN, DASS EIN PREMIUM USER AUF DER PAYMENT PAGE IST
      // this.setState({
      //   showPaymentSite: this.props.user.AboType !== "IsFreeUser",
      // });

      ApiService.authorizedClient(this.props.authKey)
        .get("TopModel/" + this.props.memberID)
        .then((response) => {
          this.setState({
            showPaymentData: response.data.ShowPaymentOption,
            showPaymentSite: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.fetchPaymentPageData();
    }
  }

  fetchPaymentPageData() {
    ApiService.clientLocalized(this.state.locale)
      .get("/SiteConfig/1")
      .then((response) => {
        this.setState({
          prePaymentText: response.data.AppPaymentPreText ?? "",
          mainPaymentText: response.data.AppPaymentMainText ?? "",
          postPaymentText: response.data.AppPaymentPostText ?? "",
        });
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      });
  }

  renderOnSitePayment = () => {
    ApiService.authorizedClient(this.props.authKey)
      .get(
        "/TopModel/" +
          this.props.memberID +
          "/initPayment?locale=" +
          this.props.locale
      )
      .then((result) => {
        this.setState({
          paymentHTML: result.data.html,
          paymentScript: result.data.script,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
        this.setState({
          error: this.props.translate("payment.alreadyPaymentDone"),
          loading: false,
        });
      });
  };

  fetchLatestCurrencies(base, to, amount) {
    let url = "https://api.exchangerate.host/latest?base=" + base;
    axios.get(url).then((result) => {
      if (typeof result.data.rates !== "undefined") {
        this.setState(
          {
            currencyBase: base,
            currencyRates: result.data.rates,
            stripePaymentAmount: amount,
          },
          () => this.convertCurrency(to, amount)
        );
      }
    });
  }

  convertCurrency(to, amount) {
    let val = amount;
    if (typeof this.state.currencyRates[to] !== "undefined") {
      val = Math.ceil(val * this.state.currencyRates[to]);
      this.setState({
        countryPaymentAmount: val.toFixed(2).replace(".00", ""),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.paymentScript === "" && this.state.paymentScript !== "") {
      // eslint-disable-next-line no-new-func
      const executeScript = new Function(this.state.paymentScript);
      executeScript();
    }
    if (this.props.locale !== prevProps.locale) {
      this.renderOnSitePayment();
    }
  }

  render() {
    return (
      <Container>
        {this.state.showPaymentSite ? (
          <div
            id="payment-page"
            className="full-height-center d-flex align-items-center payment-container"
          >
            <section className="w-100">
              <div className="pt-5">
                <Col
                  xs={12}
                  className="pb-3 header-padding"
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.parseVideo(this.state.prePaymentText),
                  }}
                />
                {/*<h3 className="text-primary">*/}
                {/*  <Translate id="payment.title" />*/}
                {/*</h3>*/}
                {/*<p>*/}
                {/*  <Translate id="payment.desc" />*/}
                {/*</p>*/}
                {/*<br />*/}
                {this.state.countryPaymentAmount &&
                this.props.user.PriceType !== "EUR" ? (
                  <>
                    <p className="text-center">
                      {/*<Translate id={"payment.otherCurrencyText"} />*/}
                      <span
                        className="font-bold"
                        dangerouslySetInnerHTML={{
                          __html: HTMLService.parseVideo(
                            this.state.mainPaymentText
                          ),
                        }}
                      ></span>
                      <b>
                        Euro{" "}
                        {this.state.stripePaymentAmount
                          .toFixed(2)
                          .replace(".00", "")}
                      </b>{" "}
                      /Jahr
                      {/*<Translate id={"payment.otherCurrencyText2"} />*/}
                      <b>
                        {this.props.user.PriceType}{" "}
                        {this.state.countryPaymentAmount}{" "}
                      </b>
                    </p>
                    <br />
                  </>
                ) : (
                  <>
                    <p className="text-center">
                      {/*<Translate id={"payment.otherCurrencyText"} />{" "}*/}
                      <b>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: HTMLService.parseVideo(
                              this.state.mainPaymentText
                            ),
                          }}
                        ></span>
                        {/*<Translate id={"payment.otherCurrencyText3"} />*/}
                        Euro{" "}
                        {this.state.stripePaymentAmount
                          .toFixed(2)
                          .replace(".00", "")}
                        /Jahr
                      </b>
                    </p>
                    <br />
                  </>
                )}
              </div>
              <Row>
                <Col
                  id="payment-holder"
                  lg={12}
                  className="d-flex justify-content-center flex-column align-items-center"
                >
                  <div className="my-3 payment-holder w-100 d-flex flex-column align-items-center justify-content-center">
                    {!this.state.activePayment ? (
                      <p className="mb-3">
                        <Translate id="payment.pleaseChoose" />
                      </p>
                    ) : this.props.user.PaymentProcessing ? (
                      !this.state.showPaymentData ? (
                        <Alert variant="info" className="text-center">
                          <b>
                            <Translate
                              id="payment.showPaymentData"
                              options={{ renderInnerHtml: true }}
                            />
                          </b>
                        </Alert>
                      ) : null
                    ) : // <Alert variant="info" className="text-center">
                    //     <b>
                    //         <Translate
                    //             id="payment.alreadyOpenPayment"
                    //             options={{renderInnerHtml: true}}
                    //         />
                    //     </b>
                    // </Alert>
                    null}
                    {this.state.loading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto d-block align-self-center"
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}
                    <div className="w-100">
                      {this.state.error ? (
                        <Alert variant="info" className="text-center">
                          {this.state.error}
                        </Alert>
                      ) : null}
                      {this.state.paymentHTML && this.state.showPaymentData ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.paymentHTML,
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col xs={12} id="scroll-to" className="py-1 my-4"></Col>
                <Col
                  xs={12}
                  className="pt-2 pb-5 header-padding"
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.parseVideo(this.state.postPaymentText),
                  }}
                />
              </Row>
            </section>
          </div>
        ) : (
          <Alert variant="danger">
            <Translate id="payment.noPermisson" />
          </Alert>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  user: state.user.user,
  locale: state.locale.locale,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
