import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ApiService } from "../../services/ApiService";
import { toastr } from "react-redux-toastr";
import { getTranslate, Translate } from "react-localize-redux";
import LazyImage from "../../components/LazyImage/LazyImage";
import { Link, withRouter } from "react-router-dom";
import Icofont from "../../components/Icofont/Icofont";
import ModelSearchActions from "../../stores/modelSearch/actions";
import "./Favourites.styles.scss";
import ChatButton from "../../components/ChatButton/ChatButton";

class SharedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      models: [],
      loading: true,
      error: "",
    };

    this.fetchModels = this.fetchModels.bind(this);
  }

  componentDidMount() {
    let hash = this.props.match.params.hash;
    if (hash) {
      this.fetchModels(hash);
    }
  }

  fetchModels(hash) {
    ApiService.client
      .get("TopModelList/?filter[Hash]=" + hash)
      .then((response) => {
        if (typeof response.data[0] !== "undefined") {
          ApiService.client
            .get("TopModelList/" + response.data[0].ID)
            .then((response) => {
              if (typeof response.data !== "undefined") {
                this.setState({
                  models: response.data.Models ?? [],
                  error: "",
                  loading: false,
                });
              } else {
                this.setState({
                  error: "No MODEL DATA SHOULD NOT HAPPEN",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              let errorMsg = this.props.translate("error.unknown");
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                errorMsg = error.response.data.message;
              }
              toastr.error(this.props.translate("toastr.error"), errorMsg);
            });
        } else {
          this.setState({
            error: "No MODEL DATA SHOULD NOT HAPPEN",
            loading: false,
          });
        }
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      });
  }

  render() {
    return (
      <Container fluid className="py-4">
        <div className="text-center">
          <h1 className="pb-4">
            <Translate id="sharedList.header" />
          </h1>
        </div>
        <Row>
          {!this.state.loading ? (
            this.state.models.length ? (
              this.state.models.map((model, index) => {
                return (
                  <Col key={model.ID} lg={6} className="mb-5 favourite-padding">
                    <div className="model-favourit-card w-100">
                      <Row className="m-lg-0">
                        <Col xs={6} className="p-lg-0">
                          <LazyImage src={model.PreviewImage} fluid />
                        </Col>
                        <Col xs={6} className="p-lg-0">
                          <div className="h-100 favourites-card-inner d-flex flex-column justify-content-between">
                            <div>
                              <p className="model-title m-0 mb-3">
                                {model.FirstName}
                              </p>
                              <small>{model.BookingCode}</small>
                              <p className="model-location m-0">
                                <b>{model.AddressTitle}</b>
                              </p>
                            </div>
                            <div className="model-teaser-actions d-md-flex flex-wrap justify-content-between d-none">
                              <Link
                                className="model-action d-flex flex-column justify-content-center"
                                to={"/model/" + model.ID}
                              >
                                <div className="text-center">
                                  <Icofont icon="search-job" />
                                </div>
                                <div className="action-title">
                                  <Translate id="modelSearch.showProfile" />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} className="d-block d-md-none">
                          <div className="model-teaser-actions d-flex flex-wrap justify-content-between py-3">
                            <Link
                              className="model-action d-flex flex-column justify-content-center"
                              to={"/model/" + model.ID}
                            >
                              <div className="text-center">
                                <Icofont icon="search-job" />
                              </div>
                              <div className="action-title text-center">
                                <Translate id="modelSearch.showProfile" />
                              </div>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col xs={12}>
                <p>
                  <Translate id="sharedList.noModels" />
                </p>
              </Col>
            )
          ) : (
            <Col xs={12}>
              <Spinner
                animation="border"
                role="status"
                className="mx-auto my-3 d-block"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  translate: getTranslate(state.localize),
});
const mapDispatchToProps = (dispatch) => ({
  setModels: (models) => dispatch(ModelSearchActions.setModels(models)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharedList));
