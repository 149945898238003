import React, { Component } from "react";
import "./AdField.style.scss";
import adImg1 from "../../assets/img/ads/ad1.png";
// import adImg2 from "../../assets/img/ads/ad2.jpg";
// import adImg3 from "../../assets/img/ads/ad3.jpg";
// import adImg4 from "../../assets/img/ads/ad4.jpg";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

export default class AdField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adImg: "",
    };
  }

  componentDidMount() {
    // const adImgArray = [adImg1, adImg2, adImg3, adImg4];
    const adImgArray = [adImg1];
    const randIndex = Math.floor(Math.random() * adImgArray.length);
    const selectedAd = adImgArray[randIndex];

    this.setState({
      adImg: `${selectedAd}`,
    });
  }

  render() {
    return (
      // Update 21.12.2023 - Update von LL ALLE WERBUNG RAUS
      <></>
      // <Link target="_blank" to={{ pathname: "https://hermes-perfectlife.at/" }}>
      //   <div className="text-center">
      //     <label className="labelAd">
      //       <Translate id="adField.adTitle" />
      //     </label>
      //     <div className="ads">
      //       <img alt="Werbung" src={this.state.adImg} />
      //     </div>
      //   </div>
      // </Link>
    );
  }
}
