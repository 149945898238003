import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import { connect } from "react-redux";
import UploadField from "../../components/UploadField/UploadField";
import UnitNumberInputField from "../../components/UnitNumberInputField/UnitNumberInputField";
import "rsuite/dist/styles/rsuite-default.css";
import { SelectPicker, TagPicker } from "rsuite";
import { ApiService } from "../../services/ApiService";
import { toastr } from "react-redux-toastr";
import { Link, withRouter } from "react-router-dom";
import { getTranslate, Translate } from "react-localize-redux";
import "./EditModelProfile.style.scss";
import LocaleActions from "../../stores/locale/actions";
import RadiusMap from "../../components/RadiusMap/RadiusMap";
import ClothingsizeDropdown from "../../components/ClothingsizeDropdown/ClothingsizeDropdown";
import VideoUploadField from "../../components/UploadField/VideoUploadField";
import { de } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UnitConvertService } from "../../services/UnitConvertionService";
import Checkbox from "@material-ui/core/Checkbox";
import options from "../../assets/country-data-native.json";
import UpgradeField from "../../components/UpgradeField/UpgradeField";
import AdField from "../../components/AdField/AdField";

class EditModelProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBodyMeasurements: false,
      age: 0,
      loading: true,
      modelStatus: "",
      mainImg: {},
      images: [],
      videos: [],
      firstName: "",
      firstNameError: "",
      surname: "",
      surnameError: "",
      height: "",
      heightSE: "",
      heightError: false,
      heightType: "cm",
      weight: "",
      weightSE: "",
      weightType: "kg",
      weightError: false,
      shoeSize: "",
      shoeSizeSE: "",
      shoeSizeType: "EU",
      shoeSizeError: false,
      chest: "",
      chestSE: "",
      chestType: "cm",
      price: "",
      priceSE: "",
      priceType: "EUR",
      priceError: false,
      searchRadius: "",
      searchRadiusSE: "",
      setSearchRadiusTrigger: "",
      searchRadiusType: "km",
      worldWide: false,
      chestError: false,
      waist: "",
      waistSE: "",
      waistType: "cm",
      waistError: false,
      clothingSize: "",
      hip: "",
      hipSE: "",
      hipType: "cm",
      hipError: false,
      gender: "",
      hairColor: "",
      eyeColor: "",
      skinType: "",
      skinColor: "",
      desc: "",
      descError: "",
      birthDate: null,
      birthDateError: "",
      addressID: 0,
      address: {},
      addressError: "",
      addressTitle: "",
      phone: "",
      adminComment: {},
      confirmDelete: false,
      languages: "",
      aboType: "",
      socialFacebook: "",
      socialInstagram: "",
      socialTiktok: "",
      socialSnapchat: "",
      socialX: "",
      socialYoutube: "",
      martialStatus: "",
    };

    this.fetchTopModelData = this.fetchTopModelData.bind(this);
    this.handleMainImgStatusChange = this.handleMainImgStatusChange.bind(this);
    this.handleMainImgDelete = this.handleMainImgDelete.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleSurnameChange = this.handleSurnameChange.bind(this);
    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleHeightTypeChange = this.handleHeightTypeChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.handleWeightTypeChange = this.handleWeightTypeChange.bind(this);
    this.handleShoeSizeChange = this.handleShoeSizeChange.bind(this);
    this.handleShoeSizeTypeChange = this.handleShoeSizeTypeChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.handleChestChange = this.handleChestChange.bind(this);
    this.handleChestTypeChange = this.handleChestTypeChange.bind(this);
    this.handleWaistChange = this.handleWaistChange.bind(this);
    this.handleWaistTypeChange = this.handleWaistTypeChange.bind(this);
    this.handleHipChange = this.handleHipChange.bind(this);
    this.handleHipTypeChange = this.handleHipTypeChange.bind(this);
    this.getDBDateString = this.getDBDateString.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateTopModelData = this.updateTopModelData.bind(this);
    this.handleHairColorChange = this.handleHairColorChange.bind(this);
    this.handleEyeColorChange = this.handleEyeColorChange.bind(this);
    this.handleSkinTypeChange = this.handleSkinTypeChange.bind(this);
    this.handleSkinColorChange = this.handleSkinColorChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.saveMainImage = this.saveMainImage.bind(this);
    this.deleteMainImage = this.deleteMainImage.bind(this);
    this.handleImagesChange = this.handleImagesChange.bind(this);
    this.handleImageStatusChange = this.handleImageStatusChange.bind(this);
    this.handleImagesDelete = this.handleImagesDelete.bind(this);
    this.saveImages = this.saveImages.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handlePriceTypeChange = this.handlePriceTypeChange.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.sendApprovalMail = this.sendApprovalMail.bind(this);
    this.deleteAdminComment = this.deleteAdminComment.bind(this);
    this.saveAdminComment = this.saveAdminComment.bind(this);
    this.handleClothingSizeChange = this.handleClothingSizeChange.bind(this);
    this.setSearchRadius = this.setSearchRadius.bind(this);
    this.setSearchRadiusTrigger = this.setSearchRadiusTrigger.bind(this);
    this.handleVideosChange = this.handleVideosChange.bind(this);
    this.handleVideosStatusChange = this.handleVideosStatusChange.bind(this);
    this.handleVideosDelete = this.handleVideosDelete.bind(this);
    this.saveVideos = this.saveVideos.bind(this);
    this.handleAddressTimeout = this.handleAddressTimeout.bind(this);
    this.updateLatLong = this.updateLatLong.bind(this);
    this.handleSocialFacebookChange =
      this.handleSocialFacebookChange.bind(this);
    this.handleSocialInstagramChange =
      this.handleSocialInstagramChange.bind(this);
    this.handleSocialTiktokChange = this.handleSocialTiktokChange.bind(this);
    this.handleSocialSnapchatChange =
      this.handleSocialSnapchatChange.bind(this);
    this.handleSocialXChange = this.handleSocialXChange.bind(this);
    this.handleSocialYoutubeChange = this.handleSocialYoutubeChange.bind(this);
    this.handleMartialStatusChange = this.handleMartialStatusChange.bind(this);
  }

  handleMartialStatusChange(val) {
    this.setState({ martialStatus: val });
  }

  handleRadiusTimeout = () => {
    clearTimeout(this.radiusTimeout);
    this.radiusTimeout = setTimeout(() => this.setSearchRadiusTrigger(), 1000);
  };
  handleAddressTimeout = () => {
    clearTimeout(this.AddressTimeout);
    this.AddressTimeout = setTimeout(() => this.updateLatLong(), 1000);
  };
  updateLatLong = () => {
    let query =
      this.state.address.Street +
      " " +
      this.state.address.Number +
      ", " +
      this.state.address.PostalCode +
      " " +
      this.state.address.City +
      ", " +
      this.state.address.Country;

    ApiService.geocodeFromAddress(query)
      .then((response) => {
        if (response.data?.results[0]?.geometry?.location) {
          this.setState({
            address: {
              ...this.state.address,
              Latitude: response.data.results[0].geometry.location.lat,
              Longitude: response.data.results[0].geometry.location.lng,
            },
          });
        }
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        console.log(errorMsg);
        //toastr.error(this.props.translate("toastr.error"), errorMsg)
      });
  };

  setSearchRadiusTrigger() {
    this.setState({ searchRadiusSETriggerChange: this.state.searchRadiusSE });
  }

  componentDidMount() {
    if (this.props.memberID) {
      this.fetchTopModelData();
    } else {
      this.props.history.push("/");
    }
  }

  setSearchRadius(radius) {
    let val = (radius / 1000).toFixed(2);
    let valSE = (radius / 1000).toFixed(2);
    if (this.state.searchRadiusType === "mi") {
      val = (val / 1.60934).toFixed(2);
    }
    this.setState({
      searchRadius: val,
      searchRadiusSE: valSE,
      worldWide: false,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.searchRadiusSE !== prevState.searchRadiusSE) {
      this.handleRadiusTimeout();
    }
    if (
      prevState.address.Latitude === this.state.address.Latitude &&
      prevState.address.Longitude === this.state.address.Longitude &&
      this.state.address.Street &&
      this.state.address.Number &&
      this.state.address.PostalCode &&
      this.state.address.City &&
      this.state.address.Country &&
      JSON.stringify(prevState.address) !== JSON.stringify(this.state.address)
    ) {
      this.handleAddressTimeout();
    }
    if (
      prevState.age !== this.state.age ||
      prevState.gender !== this.state.gender
    ) {
      if (this.state.age >= 14 * 12 && this.state.gender === "female") {
        this.setState({
          showBodyMeasurements: true,
        });
      } else {
        this.setState({
          showBodyMeasurements: false,
          chest: "",
          chestSE: "",
          waist: "",
          waistSE: "",
          hip: "",
          hipSE: "",
        });
      }
    }
  }

  calcAge(birthDate) {
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    age = age * 12 + m;
    this.setState({
      age: age,
    });
  }

  fetchTopModelData() {
    ApiService.authorizedClient(this.props.authKey)
      .get("TopModel/" + this.props.memberID)
      .then((response) => {
        this.setState(
          {
            modelStatus: response.data.Status,
            mainImg: response.data.MainImageImage ?? {},
            images: response.data.ModelImagesImage ?? [],
            videos: response.data.Videos ?? [],
            firstName: response.data.FirstName,
            surname: response.data.Surname,
            phone: response.data.Phone,
            height: response.data.Height !== 0 ? response.data.Height : "",
            heightSE: response.data.Height !== 0 ? response.data.Height : "",
            weight: response.data.Weight !== 0 ? response.data.Weight : "",
            weightSE: response.data.Weight !== 0 ? response.data.Weight : "",
            shoeSize:
              response.data.ShoeSize !== 0 ? response.data.ShoeSize : "",
            shoeSizeSE:
              response.data.ShoeSize !== 0 ? response.data.ShoeSize : "",
            chest: response.data.Chest !== 0 ? response.data.Chest : "",
            chestSE: response.data.Chest !== 0 ? response.data.Chest : "",
            waist: response.data.Waist !== 0 ? response.data.Waist : "",
            waistSE: response.data.Waist !== 0 ? response.data.Waist : "",
            hip: response.data.Hip !== 0 ? response.data.Hip : "",
            hipSE: response.data.Hip !== 0 ? response.data.Hip : "",
            price: response.data.Price !== 0 ? response.data.Price : "",
            priceSE: response.data.Price !== 0 ? response.data.Price : "",
            worldWide: response.data.WorldWide,
            aboType: response.data.AboType,
            searchRadius:
              response.data.SearchRadius !== 0
                ? response.data.SearchRadius
                : "",
            searchRadiusSE:
              response.data.SearchRadius !== 0
                ? response.data.SearchRadius
                : "",
            searchRadiusSETriggerChange:
              response.data.SearchRadius !== 0
                ? response.data.SearchRadius
                : "",
            priceType: response.data.PriceType ?? "EUR",
            birthDate: response.data.Birthdate ?? new Date(),
            gender: response.data.Gender,
            hairColor: response.data.HairColor,
            eyeColor: response.data.EyeColor,
            skinType: response.data.SkinType,
            skinColor: response.data.SkinColor,
            desc: response.data.Desc ?? "",
            address: response.data.Address,
            addressID: response.data.AddressID,
            addressTitle: response.data.FullAddressTitle,
            adminComment: response.data.AdminJsonComment
              ? JSON.parse(response.data.AdminJsonComment)
              : {},
            clothingSize: response.data.ClothingSize,
            languages: response.data.Langauges,
            loading: false,
            socialFacebook: response.data.SocialFacebook,
            socialInstagram: response.data.SocialInstagram,
            socialTiktok: response.data.SocialTiktok,
            socialSnapchat: response.data.SocialSnapchat,
            socialX: response.data.SocialX,
            socialYoutube: response.data.SocialYoutube,
            martialStatus: response.data.MartialStatus,
          },
          () => this.calcAge(new Date(response.data.Birthdate))
        );
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      });
  }

  setAddress(address) {
    let addressError = "";
    if (
      address.City === "" ||
      address.Street === "" ||
      address.Country === "" ||
      address.PostalCode === "" ||
      address.Number === ""
    ) {
      addressError = <Translate id="model.addressError" />;
    }
    this.setState({
      address: address,
      addressError: addressError,
    });
  }

  saveMainImage() {
    if (typeof this.state.mainImg.ID !== "undefined") {
      ApiService.authorizedClient(this.props.authKey)
        .put("TopModel/" + this.props.memberID + "/saveMainImage/", {
          ImageID: this.state.mainImg.ID,
        })
        .then(
          () => {
            toastr.success(
              this.props.translate("toastr.success"),
              this.props.translate("toastr.imageSaved")
            );
            this.fetchTopModelData();
          },
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    }
  }

  deleteMainImage() {
    ApiService.authorizedClient(this.props.authKey)
      .put("TopModel/" + this.props.memberID + "/saveMainImage/", {
        ImageID: "DELETE",
      })
      .then(
        () => {
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("toastr.imageDeleted")
          );
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  handleMainImgStatusChange = (currentFiles) => {
    this.setState({ mainImg: currentFiles[0] }, () => this.saveMainImage());
  };

  handleMainImgDelete(file, currentFiles) {
    this.setState({ mainImg: {} }, () => this.deleteMainImage());
  }

  handleImagesChange(e) {
    let text = e.target.value;
    this.setState({ images: text });
  }

  handleImageStatusChange = (currentFiles) => {
    this.setState({ images: currentFiles });
  };

  handleImagesDelete(file, currentFiles) {
    ApiService.authorizedClient(this.props.authKey)
      .post(`/TopModel/${this.props.memberID}/deleteImages/`, {
        Images: [file],
      })
      .then()
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      });

    this.setState({ images: currentFiles });
  }

  handleVideosChange(e) {
    let text = e.target.value;
    this.setState({ videos: text });
  }

  handleVideosStatusChange = (currentFiles) => {
    this.setState({ videos: currentFiles }, () => {
      this.saveVideos(true);
    });
  };

  handleVideosDelete(file, currentFiles) {
    ApiService.authorizedClient(this.props.authKey)
      .post(`/TopModel/${this.props.memberID}/deleteVideos/`, {
        Videos: [file],
      })
      .then()
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        toastr.error(this.props.translate("toastr.error"), errorMsg);
      });

    this.setState({ videos: currentFiles });
  }

  deleteAdminComment(type) {
    let adminComment = Object.assign({}, this.state.adminComment);
    delete adminComment[type];
    this.setState({ adminComment: adminComment }, () =>
      this.saveAdminComment()
    );
  }

  saveAdminComment() {
    let id = this.props.memberID;
    if (!isNaN(id) && id && Object.keys(this.state.adminComment).length) {
      ApiService.authorizedClient(this.props.authKey)
        .put("TopModel/" + id, {
          AdminJsonComment: JSON.stringify(this.state.adminComment),
        })
        .then(
          () => {},
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    }
  }

  saveImages() {
    if (typeof this.state.mainImg.ID !== "undefined") {
      ApiService.authorizedClient(this.props.authKey)
        .put("TopModel/" + this.props.memberID + "/saveModelImages/", {
          Images: this.state.images,
        })
        .then(
          () => {
            this.fetchTopModelData();
            if (this.state.videos.length) {
              this.saveVideos();
            } else {
              this.saveAddress();
            }

            // toastr.success('Erfolgreich', 'Ihre Bilder wurden gespeichert')
          },
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    }
  }

  saveVideos(noAction = false) {
    if (typeof this.state.mainImg.ID !== "undefined") {
      ApiService.authorizedClient(this.props.authKey)
        .put("TopModel/" + this.props.memberID + "/saveModelVideos/", {
          Videos: this.state.videos,
        })
        .then(
          () => {
            if (!noAction) {
              this.fetchTopModelData();
              this.saveAddress();
            }

            // toastr.success('Erfolgreich', 'Ihre Bilder wurden gespeichert')
          },
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    }
  }

  handleFirstNameChange(e) {
    let text = e.target.value;
    this.setState({ firstName: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({
        firstNameError: <Translate id="error.wrongFirstName" />,
        disableButton: true,
      });
    } else {
      this.setState({ firstNameError: "" });
    }
  }

  handleSurnameChange(e) {
    let text = e.target.value;
    this.setState({ surname: text });
    if (text.match(/^[^0-9\n]+$/) === null) {
      this.setState({
        surnameError: <Translate id="error.wrongSurname" />,
        disableButton: true,
      });
    } else {
      this.setState({ surnameError: "" });
    }
  }

  handleGenderChange(val) {
    this.setState({ gender: val });
  }

  handleBirthdayChange(date) {
    if (date instanceof Date && isFinite(date)) {
      let currentDate = new Date();
      if (+date > +currentDate) {
        this.setState({
          birthDateError: <Translate id="model.birthDateError" />,
        });
      } else {
        this.setState({ birthDate: date, birthDateError: "" });
        this.calcAge(date);
      }
    } else {
      this.setState({
        birthDateError: <Translate id="model.birthDateError" />,
      });
    }
  }

  handleHeightChange(val, error, valSE) {
    this.setState({
      height: val,
      heightSE: valSE,
      heightError: error,
    });
  }

  handleHeightTypeChange(val) {
    this.setState({ heightType: val });
  }

  handleWeightChange(val, error, valSE) {
    this.setState({
      weight: val,
      weightSE: valSE,
      weightError: error,
    });
  }

  handleWeightTypeChange(val) {
    this.setState({ weightType: val });
  }

  handleShoeSizeChange(val, error, valSE) {
    this.setState({
      shoeSize: val,
      shoeSizeSE: valSE,
      shoeSizeError: error,
    });
  }

  handleShoeSizeTypeChange(val) {
    this.setState({ shoeSizeType: val });
  }

  handleChestChange(val, error, valSE) {
    this.setState({
      chest: val,
      chestSE: valSE,
      chestError: error,
    });
  }

  handleChestTypeChange(val) {
    this.setState({ chestType: val });
  }

  handleWaistChange(val, error, valSE) {
    this.setState({
      waist: val,
      waistSE: valSE,
      waistError: error,
    });
  }

  handleWaistTypeChange(val) {
    this.setState({ waistType: val });
  }

  handleHipChange(val, error, valSE) {
    this.setState({
      hip: val,
      hipSE: valSE,
      hipError: error,
    });
  }

  handleHipTypeChange(val) {
    this.setState({ hipType: val });
  }

  handlePriceChange(val, error, valSE) {
    this.setState({
      price: val,
      priceSE: valSE,
      priceError: error,
    });
  }

  handlePriceTypeChange(val) {
    this.setState({ priceType: val });
  }

  handleSearchRadiusChange(val, error, valSE) {
    this.setState({
      searchRadius: val,
      searchRadiusSE: valSE,
      searchRadiusError: error,
    });
  }

  handleSearchRadiusTypeChange(val) {
    this.setState({ searchRadiusType: val });
  }

  handleHairColorChange(val) {
    this.setState({ hairColor: val });
  }

  handleEyeColorChange(val) {
    this.setState({ eyeColor: val });
  }

  handleClothingSizeChange(val) {
    this.setState({
      clothingSize: val,
    });
  }

  handleSkinTypeChange(val) {
    this.setState({ skinType: val });
  }

  handleSkinColorChange(val) {
    this.setState({ skinColor: val });
  }

  handleDescChange(e) {
    let text = e.target.value;
    let error = "";
    if (text.length > 500) {
      error = <Translate id="model.descError" />;
    }
    this.setState({
      desc: text,
      descError: error,
    });
  }

  handleSocialFacebookChange(e) {
    this.setState({ socialFacebook: e.target.value });
  }

  handleSocialInstagramChange(e) {
    this.setState({ socialInstagram: e.target.value });
  }

  handleSocialTiktokChange(e) {
    this.setState({ socialTiktok: e.target.value });
  }

  handleSocialSnapchatChange(e) {
    this.setState({ socialSnapchat: e.target.value });
  }

  handleSocialXChange(e) {
    this.setState({ socialX: e.target.value });
  }

  handleSocialYoutubeChange(e) {
    this.setState({ socialYoutube: e.target.value });
  }

  getDBDateString(dateToFormat) {
    let date = dateToFormat;
    let dateString = null;

    if (date && typeof date === "object") {
      dateString =
        date.getUTCFullYear() +
        "-" +
        (date.getUTCMonth() + 1) +
        "-" +
        date.getUTCDate() +
        " 23:59:59";
    }
    if (date && typeof date === "string") {
      dateString = date;
    }
    return dateString;
  }

  handleSubmit() {
    this.setState({ error: "" });

    this.updateTopModelData();
  }

  updateTopModelData() {
    if (
      this.state.firstNameError.length > 0 ||
      this.state.surnameError.length > 0 ||
      this.state.birthDateError.length > 0 ||
      this.state.heightError ||
      this.state.weightError ||
      this.state.shoeSizeError ||
      this.state.chestError ||
      this.state.hipError ||
      this.state.waistError ||
      this.state.descError ||
      this.state.addressError
    ) {
      this.setState({ error: <Translate id="error.checkInput" /> });
      return;
    }
    let searchRadius = this.state.searchRadiusSE;
    if (this.state.worldWide) {
      searchRadius = 55000;
    }
    ApiService.authorizedClient(this.props.authKey)
      .put("TopModel/" + this.props.memberID, {
        FirstName: this.state.firstName,
        Surname: this.state.surname,
        Phone: this.state.phone,
        Height: this.state.heightSE,
        Weight: this.state.weightSE,
        ShoeSize: this.state.shoeSizeSE,
        Chest: this.state.chestSE,
        Waist: this.state.waistSE,
        Hip: this.state.hipSE,
        Birthdate: this.state.birthDate,
        Gender: this.state.gender,
        HairColor: this.state.hairColor,
        EyeColor: this.state.eyeColor,
        SkinType: this.state.skinType,
        SkinColor: this.state.skinColor,
        Desc: this.state.desc,
        Price: this.state.price,
        PriceType: this.state.priceType,
        ClothingSize: this.state.clothingSize,
        SearchRadius: searchRadius,
        WorldWide: this.state.worldWide,
        HasChangedSinceFeedBack: true,
        Langauges: this.state.languages,
        aboType: this.state.aboType,
        SocialFacebook: this.state.socialFacebook,
        SocialInstagram: this.state.socialInstagram,
        SocialTiktok: this.state.socialTiktok,
        SocialSnapchat: this.state.socialSnapchat,
        SocialX: this.state.socialX,
        SocialYoutube: this.state.socialYoutube,
        MartialStatus: this.state.martialStatus,
      })
      .then(
        () => {
          toastr.success(
            this.props.translate("toastr.success"),
            this.props.translate("toastr.dataSaved")
          );
          this.saveImages();
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  saveAddress() {
    let data = this.state.address;
    data.MemberID = this.props.memberID;
    if (this.state.addressID) {
      ApiService.authorizedClient(this.props.authKey)
        .put("Address/" + this.state.addressID, data)
        .then(
          (response) => {
            ApiService.authorizedClient(this.props.authKey)
              .put("TopModel/" + this.props.memberID, {
                AddressID: response.data.ID,
              })
              .then(
                () => {
                  if (this.state.modelStatus === "Active") {
                    this.props.history.push("/model/" + this.props.memberID);
                  }
                },
                (error) => {
                  let errorMsg = this.props.translate("error.unknown");
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    errorMsg = error.response.data.message;
                  }
                  toastr.error(this.props.translate("toastr.error"), errorMsg);
                }
              );
          },
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    } else {
      ApiService.authorizedClient(this.props.authKey)
        .post("Address/", data)
        .then(
          (response) => {
            ApiService.authorizedClient(this.props.authKey)
              .put("TopModel/" + this.props.memberID, {
                AddressID: response.data.ID,
              })
              .then(
                () => {},
                (error) => {
                  let errorMsg = this.props.translate("error.unknown");
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    errorMsg = error.response.data.message;
                  }
                  toastr.error(this.props.translate("toastr.error"), errorMsg);
                }
              );
          },
          (error) => {
            let errorMsg = this.props.translate("error.unknown");
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMsg = error.response.data.message;
            }
            toastr.error(this.props.translate("toastr.error"), errorMsg);
          }
        );
    }
  }

  sendApprovalMail() {
    this.updateTopModelData();

    if (
      this.state.firstName.length <= 0 ||
      this.state.surname.length <= 0 ||
      this.state.height === "" ||
      this.state.weight === "" ||
      this.state.gender === "" ||
      this.state.addressTitle === "" ||
      this.state.searchRadius.length <= 0 ||
      this.state.birthDate === ""
    ) {
      this.setState({ error: <Translate id="error.enterAllRequiredFields" /> });
      return;
    }
    ApiService.authorizedClient(this.props.authKey)
      .post("TopModel/" + this.props.memberID + "/submitApprovalProcess", {})
      .then(
        () => {
          this.fetchTopModelData();
        },
        (error) => {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          toastr.error(this.props.translate("toastr.error"), errorMsg);
        }
      );
  }

  render() {
    return (
      <Container>
        <section className="section pt-5 pb-5">
          <Row>
            <Col xs={12} className="text-center">
              <h3>
                <Translate id="model.welcome" />
              </h3>
            </Col>
            {this.state.modelStatus === "ApprovalPending" &&
            Object.keys(this.state.adminComment).length ? (
              <Col xs={12} className="my-3">
                <Alert variant="info">
                  <Translate
                    id="model-dashboard.profileChanges"
                    data={{
                      count: Object.keys(this.state.adminComment).length,
                    }}
                    options={{ renderInnerHtml: true }}
                  />
                </Alert>
              </Col>
            ) : null}
          </Row>
          <Row>
            {this.state.loading ? (
              <Spinner
                animation="border"
                role="status"
                className="mx-auto d-block align-self-center"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Form className="col-12">
                {this.state.aboType === "IsFreeUser" && <AdField />}
                <div className="from-section-label">
                  <Translate id="model.profilePicture" />
                  <span className="label-desc">
                    <Translate id="model.profilePictureText" />
                  </span>
                </div>
                <Form.Group>
                  {typeof this.state.mainImg !== "undefined" &&
                  this.state.mainImg.ID ? (
                    <UploadField
                      onChange={this.handleMainImgStatusChange}
                      inputContent={<Translate id="upload.descOne" />}
                      maxFiles={1}
                      onDelete={this.handleMainImgDelete}
                      files={[this.state.mainImg]}
                      alloweEdit={true}
                      adminCommentType="MainImage"
                      adminComment={
                        this.state.modelStatus === "ApprovalPending"
                          ? this.state.adminComment
                          : {}
                      }
                      deleteAdminComment={this.deleteAdminComment}
                    />
                  ) : (
                    <UploadField
                      onChange={this.handleMainImgStatusChange}
                      onDelete={this.handleMainImgDelete}
                      inputContent={<Translate id="upload.descOne" />}
                      maxFiles={1}
                      adminCommentType="MainImage"
                      adminComment={
                        this.state.modelStatus === "ApprovalPending"
                          ? this.state.adminComment
                          : {}
                      }
                      deleteAdminComment={this.deleteAdminComment}
                    />
                  )}
                </Form.Group>
                <div className="from-section-label mt-5 mb-3">
                  <Translate id="model.mydata" />
                </div>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputFirstName">
                    <Translate id="model.firstName" />*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputFirstName"
                    onChange={this.handleFirstNameChange}
                    disabled={this.state.loading}
                    value={this.state.firstName}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.firstNameError}</p>
                  {typeof this.state.adminComment["FirstName"] !==
                    "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["FirstName"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputLastName">
                    <Translate id="model.surname" />*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="inputLastName"
                    onChange={this.handleSurnameChange}
                    disabled={this.state.loading}
                    value={this.state.surname}
                    className="input-foreground"
                  />
                  <p className="text-danger m-0">{this.state.surnameError}</p>
                  {typeof this.state.adminComment["Surname"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["Surname"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group phone-group">
                  <Form.Label htmlFor="inputPhone">
                    <Translate id="model.phone" />
                  </Form.Label>
                  <div dir="ltr">
                    <PhoneInput
                      country={"at"}
                      value={this.state.phone}
                      onChange={(phone) => this.setState({ phone })}
                      placeholder={"+43 1 23456"}
                    />
                  </div>
                  <p className="text-danger m-0"></p>
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputGender">
                    <Translate id="model.gender" />*
                  </Form.Label>
                  <SelectPicker
                    block
                    placeholder={<Translate id="model.pleaseChoose" />}
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        value: "female",
                        label: <Translate id="model.gender_female" />,
                      },
                      {
                        value: "male",
                        label: <Translate id="model.gender_male" />,
                      },
                    ]}
                    value={this.state.gender}
                    onChange={this.handleGenderChange}
                  />
                  {typeof this.state.adminComment["Gender"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["Gender"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                {this.state.age > 216 && this.state.birthDate ? (
                  <Form.Group className="from-label-group">
                    <Form.Label htmlFor="inputMartialStatus">
                      <Translate id="model.martialStatus" />
                    </Form.Label>
                    <SelectPicker
                      block
                      placeholder={<Translate id="model.pleaseChoose" />}
                      searchable={false}
                      cleanable={false}
                      data={[
                        {
                          value: "None",
                          label: <Translate id="model.martialStatus_none" />,
                        },
                        {
                          value: "Single",
                          label: <Translate id="model.martialStatus_single" />,
                        },
                        {
                          value: "Beziehung",
                          label: (
                            <Translate id="model.martialStatus_beziehung" />
                          ),
                        },
                        {
                          value: "Verheiratet",
                          label: (
                            <Translate id="model.martialStatus_verheiratet" />
                          ),
                        },
                      ]}
                      value={this.state.martialStatus}
                      onChange={this.handleMartialStatusChange}
                    />
                  </Form.Group>
                ) : null}
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputBirthday">
                    <Translate id="model.birthDate" />
                  </Form.Label>
                  <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      maxDate={new Date()}
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd.MM.yyyy"
                      value={this.state.birthDate}
                      locale={"de-DE"}
                      onChange={this.handleBirthdayChange}
                      cancelLabel={<Translate id="model.birthDateCancel" />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    {this.state.birthDateError.length <= 0 ? (
                      <p className="text-muted m-0">
                        <Translate id="model.birthDateFormat" />
                      </p>
                    ) : (
                      <p className="text-danger m-0">
                        {this.state.birthDateError}
                        <br />
                        <Translate id="model.birthDateFormat" />
                      </p>
                    )}
                  </MuiPickersUtilsProvider>
                  {typeof this.state.adminComment["Age"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["Age"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <UnitNumberInputField
                    id="inputHeight"
                    placeholder={<Translate id="model.height" />}
                    required={true}
                    errorMessage={<Translate id="model.noValidValue" />}
                    value={this.state.height}
                    loading={this.state.loading}
                    valueType={this.state.heightType}
                    unitType="Size"
                    handelValueSet={(val, error, valSE) =>
                      this.handleHeightChange(val, error, valSE)
                    }
                    handleTypeSet={(val) => this.handleHeightTypeChange(val)}
                    adminCommentType={"Height"}
                    adminComment={
                      this.state.modelStatus === "ApprovalPending"
                        ? this.state.adminComment
                        : {}
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <UnitNumberInputField
                    id="inputWeight"
                    placeholder={<Translate id="model.weight" />}
                    required={true}
                    errorMessage={<Translate id="model.noValidValue" />}
                    value={this.state.weight}
                    loading={this.state.loading}
                    valueType={this.state.weightType}
                    unitType="Weight"
                    handelValueSet={(val, error, valSE) =>
                      this.handleWeightChange(val, error, valSE)
                    }
                    handleTypeSet={(val) => this.handleWeightTypeChange(val)}
                    adminCommentType={"Weight"}
                    adminComment={
                      this.state.modelStatus === "ApprovalPending"
                        ? this.state.adminComment
                        : {}
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <UnitNumberInputField
                    id="inputShoeSize"
                    placeholder={<Translate id="model.shoesize" />}
                    errorMessage={<Translate id="model.noValidValue" />}
                    value={this.state.shoeSize}
                    loading={this.state.loading}
                    valueType={this.state.shoeSizeType}
                    gender={this.state.gender}
                    unitType="Shoe"
                    handelValueSet={(val, error, valSE) =>
                      this.handleShoeSizeChange(val, error, valSE)
                    }
                    handleTypeSet={(val) => this.handleShoeSizeTypeChange(val)}
                    adminCommentType={"ShoeSize"}
                    adminComment={
                      this.state.modelStatus === "ApprovalPending"
                        ? this.state.adminComment
                        : {}
                    }
                  />
                </Form.Group>
                {this.state.showBodyMeasurements ? (
                  <>
                    <Form.Group>
                      <UnitNumberInputField
                        id="inputWaist"
                        placeholder={<Translate id="model.waist" />}
                        errorMessage={<Translate id="model.noValidValue" />}
                        value={this.state.waist}
                        loading={this.state.loading}
                        valueType={this.state.waistType}
                        unitType="Size"
                        handelValueSet={(val, error, valSE) =>
                          this.handleWaistChange(val, error, valSE)
                        }
                        handleTypeSet={(val) => this.handleWaistTypeChange(val)}
                        adminCommentType={"Waist"}
                        adminComment={
                          this.state.modelStatus === "ApprovalPending"
                            ? this.state.adminComment
                            : {}
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <UnitNumberInputField
                        id="inputHip"
                        placeholder={<Translate id="model.hip" />}
                        errorMessage={<Translate id="model.noValidValue" />}
                        value={this.state.hip}
                        loading={this.state.loading}
                        valueType={this.state.hipType}
                        unitType="Size"
                        handelValueSet={(val, error, valSE) =>
                          this.handleHipChange(val, error, valSE)
                        }
                        handleTypeSet={(val) => this.handleHipTypeChange(val)}
                        adminCommentType={"Hip"}
                        adminComment={
                          this.state.modelStatus === "ApprovalPending"
                            ? this.state.adminComment
                            : {}
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <UnitNumberInputField
                        id="inputChest"
                        placeholder={<Translate id="model.chest" />}
                        errorMessage={<Translate id="model.noValidValue" />}
                        value={this.state.chest}
                        loading={this.state.loading}
                        valueType={this.state.chestType}
                        unitType="Size"
                        handelValueSet={(val, error, valSE) =>
                          this.handleChestChange(val, error, valSE)
                        }
                        handleTypeSet={(val) => this.handleChestTypeChange(val)}
                        adminCommentType={"Chest"}
                        adminComment={
                          this.state.modelStatus === "ApprovalPending"
                            ? this.state.adminComment
                            : {}
                        }
                      />
                    </Form.Group>
                  </>
                ) : null}
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputClothingSize">
                    <Translate id="model.clothingSize" />
                  </Form.Label>
                  <ClothingsizeDropdown
                    age={this.state.age}
                    value={this.state.clothingSize}
                    handleClothingSizeChange={this.handleClothingSizeChange}
                  />
                  {typeof this.state.adminComment["ClothingSize"] !==
                    "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["ClothingSize"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputHairColor">
                    <Translate id="model.hair" />
                  </Form.Label>
                  <SelectPicker
                    block
                    id="inputHairColor"
                    placeholder={<Translate id="model.pleaseChoose" />}
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        value: "blond",
                        label: <Translate id="color.c_blond" />,
                      },
                      {
                        value: "brunette",
                        label: <Translate id="color.c_brunette" />,
                      },
                      {
                        value: "brown",
                        label: <Translate id="color.c_brown" />,
                      },
                      { value: "red", label: <Translate id="color.c_red" /> },
                      {
                        value: "black",
                        label: <Translate id="color.c_black" />,
                      },
                      { value: "gray", label: <Translate id="color.c_gray" /> },
                      {
                        value: "other",
                        label: <Translate id="color.c_other" />,
                      },
                    ]}
                    value={this.state.hairColor}
                    onChange={this.handleHairColorChange}
                  />
                  {typeof this.state.adminComment["HairColor"] !==
                    "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["HairColor"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputEyeColor">
                    <Translate id="model.eyes" />
                  </Form.Label>
                  <SelectPicker
                    block
                    id="inputEyeColor"
                    placeholder={<Translate id="model.pleaseChoose" />}
                    searchable={false}
                    cleanable={false}
                    data={[
                      { value: "blue", label: <Translate id="color.c_blue" /> },
                      {
                        value: "green",
                        label: <Translate id="color.c_green" />,
                      },
                      {
                        value: "brown",
                        label: <Translate id="color.c_brown" />,
                      },
                      { value: "gray", label: <Translate id="color.c_gray" /> },
                      {
                        value: "other",
                        label: <Translate id="color.c_other" />,
                      },
                    ]}
                    value={this.state.eyeColor}
                    onChange={this.handleEyeColorChange}
                  />
                  {typeof this.state.adminComment["EyeColor"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["EyeColor"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputSkinType">
                    <Translate id="model.skintype" />
                  </Form.Label>
                  <SelectPicker
                    block
                    id="inputSkinType"
                    placeholder={<Translate id="model.pleaseChoose" />}
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        value: "european",
                        label: <Translate id="skintype.st_european" />,
                      },
                      {
                        value: "asian",
                        label: <Translate id="skintype.st_asian" />,
                      },
                      {
                        value: "african",
                        label: <Translate id="skintype.st_african" />,
                      },
                      {
                        value: "other",
                        label: <Translate id="skintype.st_other" />,
                      },
                    ]}
                    value={this.state.skinType}
                    onChange={this.handleSkinTypeChange}
                  />
                  {typeof this.state.adminComment["SkinType"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["SkinType"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group className="from-label-group">
                  <Form.Label htmlFor="inputSkinColor">
                    <Translate id="model.skincolor" />
                  </Form.Label>
                  <SelectPicker
                    block
                    id="inputSkinColor"
                    placeholder={<Translate id="model.pleaseChoose" />}
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        value: "light",
                        label: <Translate id="skincolor.sc_light" />,
                      },
                      {
                        value: "middle",
                        label: <Translate id="skincolor.sc_middle" />,
                      },
                      {
                        value: "dark",
                        label: <Translate id="skincolor.sc_dark" />,
                      },
                      {
                        value: "other",
                        label: <Translate id="skincolor.sc_other" />,
                      },
                    ]}
                    value={this.state.skinColor}
                    onChange={this.handleSkinColorChange}
                  />
                  {typeof this.state.adminComment["SkinColor"] !==
                    "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["SkinColor"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                {/*<div className="from-section-label mt-5">*/}
                {/*  <Translate id="model.myAboHeadline" />*/}
                {/*  <span className="label-desc">*/}
                {/*    <Translate id="model.myAboDesc" />*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*TODO Model ABO Packet*/}
                {/*<Form.Group className="from-label-group">*/}
                {/*  <div className="d-flex align-items-center">*/}
                {/*    <Checkbox*/}
                {/*      id="isFreeUser"*/}
                {/*      checked={this.state.isFreeUser}*/}
                {/*      onChange={() =>*/}
                {/*        this.setState({*/}
                {/*          isFreeUser: !this.state.isFreeUser,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    ></Checkbox>*/}
                {/*    <label htmlFor="isFreeUser" className="cursor-pointer-imp">*/}
                {/*      <Translate id="model.isFreeUser" />*/}
                {/*    </label>*/}
                {/*  </div>*/}
                {/*</Form.Group>*/}
                {/*<Form.Group className="from-label-group">*/}
                {/*  <div className="d-flex align-items-center">*/}
                {/*    <Checkbox*/}
                {/*      id="isPremiumUser"*/}
                {/*      checked={this.state.isPremiumUser}*/}
                {/*      onChange={() =>*/}
                {/*        this.setState({*/}
                {/*          isPremiumUser: !this.state.isPremiumUser,*/}
                {/*        })*/}
                {/*      }*/}
                {/*    ></Checkbox>*/}
                {/*    <label*/}
                {/*      htmlFor="isPremiumUser"*/}
                {/*      className="cursor-pointer-imp"*/}
                {/*    >*/}
                {/*      <Translate id="model.isPremiumUser" />*/}
                {/*    </label>*/}
                {/*  </div>*/}
                {/*</Form.Group>*/}
                <div className="from-section-label mt-5">
                  <Translate id="model.homeTownAndRadius" />
                  <span className="label-desc">
                    <Translate id="model.homeTownAndRadiusText" />
                  </span>
                </div>
                {/*<Form.Group className='from-label-group'>*/}
                {/*    <Form.Label htmlFor='inputLocationColor'><Translate*/}
                {/*        id="model.homeTown"/>*</Form.Label>*/}
                {/*    <LocationField*/}
                {/*        setAddress={address => this.setAddress(address)}*/}
                {/*        address={this.state.addressTitle}*/}
                {/*    />*/}
                {/*    {!this.state.addressError ?*/}
                {/*        <p className='text-muted m-0'><Translate id="model.addressError"/></p>*/}
                {/*        :*/}
                {/*        <p className='text-muted m-0'>{this.state.addressError}</p>*/}
                {/*    }*/}
                {/*    {typeof this.state.adminComment['Address'] !== 'undefined' && this.state.modelStatus === 'ApprovalPending' ?*/}
                {/*        <Row className="text-danger">*/}
                {/*            <Col xs={6} lg={2}>*/}
                {/*                <b><Translate id="model.comment"/></b>*/}
                {/*            </Col>*/}
                {/*            <Col xs={6} lg={10}>*/}
                {/*                {this.state.adminComment['Address'].message}*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        :*/}
                {/*        null*/}
                {/*    }*/}
                {/*</Form.Group>*/}
                <Row>
                  <Col lg={6}>
                    <Form.Group
                      className={
                        "from-label-group " +
                        (this.state.address.Street
                          ? ""
                          : this.state.addressShowRequired)
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="model.street" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputStreet"
                        onChange={(e) =>
                          this.setState({
                            address: {
                              ...this.state.address,
                              Street: e.target.value,
                            },
                          })
                        }
                        disabled={this.state.loading}
                        value={this.state.address.Street}
                        className="input-foreground"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={
                        "from-label-group " +
                        (this.state.address.Number
                          ? ""
                          : this.state.addressShowRequired)
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="model.streetNumber" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputNumber"
                        onChange={(e) =>
                          this.setState({
                            address: {
                              ...this.state.address,
                              Number: e.target.value,
                            },
                          })
                        }
                        disabled={this.state.loading}
                        value={this.state.address.Number}
                        className="input-foreground"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={
                        "from-label-group " +
                        (this.state.address.PostalCode
                          ? ""
                          : this.state.addressShowRequired)
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="model.postalCode" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputZIP"
                        onChange={(e) =>
                          this.setState({
                            address: {
                              ...this.state.address,
                              PostalCode: e.target.value,
                            },
                          })
                        }
                        disabled={this.state.loading}
                        value={this.state.address.PostalCode}
                        className="input-foreground"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={
                        "from-label-group " +
                        (this.state.address.City
                          ? ""
                          : this.state.addressShowRequired)
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="model.city" />*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputCity"
                        onChange={(e) =>
                          this.setState({
                            address: {
                              ...this.state.address,
                              City: e.target.value,
                            },
                          })
                        }
                        disabled={this.state.loading}
                        value={this.state.address.City}
                        className="input-foreground"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group
                      className={
                        "from-label-group " +
                        (this.state.address.Country
                          ? ""
                          : this.state.addressShowRequired)
                      }
                    >
                      <Form.Label htmlFor="inputLocationColor">
                        <Translate id="model.country" />*
                      </Form.Label>
                      <SelectPicker
                        block
                        placeholder={<Translate id="model.pleaseChoose" />}
                        searchable={false}
                        cleanable={false}
                        data={options}
                        value={this.state.address.Country ?? ""}
                        onChange={(val) =>
                          this.setState({
                            address: {
                              ...this.state.address,
                              Country: val,
                            },
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!this.state.worldWide ? (
                  <Form.Group>
                    <UnitNumberInputField
                      id="inputDistance"
                      placeholder={<Translate id="model.searchRadius" />}
                      required={true}
                      errorMessage={<Translate id="model.noValidValue" />}
                      value={this.state.searchRadius}
                      loading={this.state.loading}
                      valueType={this.state.searchRadiusType}
                      unitType="Distance"
                      handelValueSet={(val, error, valSE) =>
                        this.handleSearchRadiusChange(val, error, valSE)
                      }
                      handleTypeSet={(val) =>
                        this.handleSearchRadiusTypeChange(val)
                      }
                      adminCommentType={"SearchRadius"}
                      adminComment={
                        this.state.modelStatus === "ApprovalPending"
                          ? this.state.adminComment
                          : {}
                      }
                    />
                  </Form.Group>
                ) : null}
                <Form.Group className="from-label-group">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      id="worldWide"
                      checked={this.state.worldWide}
                      onChange={() =>
                        this.setState({
                          worldWide: !this.state.worldWide,
                          searchRadiusType: "km",
                          searchRadius: this.state.worldWide ? 100 : 55000,
                          searchRadiusSETriggerChange: this.state.worldWide
                            ? 100
                            : 55000,
                        })
                      }
                    ></Checkbox>
                    <label for="worldWide" className="cursor-pointer-imp">
                      <Translate id="model.worldWide" />
                    </label>
                  </div>
                </Form.Group>
                {this.state.worldWide ? null : (
                  <RadiusMap
                    lat={this.state.address.Latitude}
                    lng={this.state.address.Longitude}
                    radius={this.state.searchRadiusSETriggerChange * 1000}
                    setSearchRadius={(radius) => this.setSearchRadius(radius)}
                  />
                )}
                <div className="from-section-label mt-5">
                  <Translate id="model.price" />
                  <span className="label-desc">
                    <Translate id="model.priceText" />
                  </span>
                </div>
                <Form.Group>
                  <UnitNumberInputField
                    id="inputPrice"
                    placeholder={<Translate id="model.priceHour" />}
                    errorMessage={<Translate id="model.noValidValue" />}
                    value={this.state.price}
                    loading={this.state.loading}
                    valueType={this.state.priceType}
                    unitType="Currency"
                    handelValueSet={(val, error, valSE) =>
                      this.handlePriceChange(val, error, valSE)
                    }
                    handleTypeSet={(val) => this.handlePriceTypeChange(val)}
                    adminCommentType={"Price"}
                    adminComment={
                      this.state.modelStatus === "ApprovalPending"
                        ? this.state.adminComment
                        : {}
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <div className="from-section-label mt-5">
                    <Translate id="model.desc" />
                    <span className="label-desc">
                      <Translate id="model.descText" />
                    </span>
                  </div>
                  <div className="position-relative mb-1">
                    <Form.Control
                      as="textarea"
                      rows="4"
                      id="inputDesc"
                      onChange={this.handleDescChange}
                      disabled={this.state.loading}
                      value={this.state.desc}
                      className="input-foreground"
                    />
                    <div
                      className={
                        "character-counter " +
                        (this.state.desc.length > 500 ? "error" : "")
                      }
                    >
                      ({this.state.desc.length}/500)
                    </div>
                  </div>
                  <p className="text-danger m-0">{this.state.descError}</p>
                  {typeof this.state.adminComment["Desc"] !== "undefined" &&
                  this.state.modelStatus === "ApprovalPending" ? (
                    <Row className="text-danger">
                      <Col xs={6} lg={2}>
                        <b>
                          <Translate id="model.comment" />
                        </b>
                      </Col>
                      <Col xs={6} lg={10}>
                        {this.state.adminComment["Desc"].message}
                      </Col>
                    </Row>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <div className="from-section-label mt-3">
                    <small>
                      <Translate id="model.languages" />
                    </small>
                    <span className="label-desc mt-1">
                      <Translate id="model.languagesText" />
                    </span>
                  </div>
                  <TagPicker
                    value={
                      this.state.languages
                        ? JSON.parse(this.state.languages)
                        : ""
                    }
                    width={"100%"}
                    placeholder={<Translate id="model.pleaseChoose" />}
                    data={UnitConvertService.langauges}
                    onChange={(value) =>
                      this.setState({ languages: JSON.stringify(value) })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <div className="from-section-label mt-5">
                    <Translate id="model.socialheadline" />
                    <span className="label-desc">
                      <Translate id="model.socialdesc" />
                    </span>
                  </div>
                  {this.state.aboType === "IsFreeUser" ? (
                    <Link to="/upgrade">
                      <Translate id="model.socailgetpremium" />
                    </Link>
                  ) : (
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputFacebook">
                            <Translate id="model.socialFacebook" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputFacebook"
                            onChange={this.handleSocialFacebookChange}
                            disabled={this.state.loading}
                            value={this.state.socialFacebook}
                            className="input-foreground"
                            placeholder="https://www.facebook.com/DEIN_PROFIL/"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputInstagram">
                            <Translate id="model.socialInstagram" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputInstagram"
                            onChange={this.handleSocialInstagramChange}
                            disabled={this.state.loading}
                            value={this.state.socialInstagram}
                            className="input-foreground"
                            placeholder="https://www.instagram.com/DEIN_PROFIL/"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputTiktok">
                            <Translate id="model.socialTiktok" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputTiktok"
                            onChange={this.handleSocialTiktokChange}
                            disabled={this.state.loading}
                            value={this.state.socialTiktok}
                            className="input-foreground"
                            placeholder="https://www.tiktok.com/@DEIN_PROFIL"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputSnapchat">
                            <Translate id="model.socialSnapchat" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputSnapchat"
                            onChange={this.handleSocialSnapchatChange}
                            disabled={this.state.loading}
                            value={this.state.socialSnapchat}
                            className="input-foreground"
                            placeholder="https://www.snapchat.com/DEIN_PROFIL"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputX">
                            <Translate id="model.socialX" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputX"
                            onChange={this.handleSocialXChange}
                            disabled={this.state.loading}
                            value={this.state.socialX}
                            className="input-foreground"
                            placeholder="https://twitter.com/DEIN_PROFIL"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="from-label-group">
                          <Form.Label htmlFor="inputYoutube">
                            <Translate id="model.socialYoutube" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="inputYoutube"
                            onChange={this.handleSocialYoutubeChange}
                            disabled={this.state.loading}
                            value={this.state.socialYoutube}
                            className="input-foreground"
                            placeholder="https://www.youtube.com/@DEIN_HANDLE"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Form.Group>
                <Form.Group className="mt-5">
                  <div className="from-section-label">
                    <Translate id="model.moreimages" />
                    {this.state.aboType === "IsFreeUser" ? (
                      <span className="label-desc mt-1">
                        <Translate id="model.moreimagesText" />
                      </span>
                    ) : (
                      <span className="label-desc mt-1">
                        <Translate id="model.premiumMoreimagesText" />
                      </span>
                    )}
                  </div>
                  {this.state.aboType === "IsFreeUser" && (
                    <div className="mb-2">
                      <Link to="/upgrade">
                        <Translate id="model.moreimagesTextGetPremiumUpgrade" />
                      </Link>
                    </div>
                  )}
                  <UploadField
                    onChange={this.handleImageStatusChange}
                    onDelete={this.handleImagesDelete}
                    files={this.state.images}
                    alloweEdit={true}
                    adminCommentType="IMG_"
                    adminComment={
                      this.state.modelStatus === "ApprovalPending"
                        ? this.state.adminComment
                        : {}
                    }
                    disableDropzone={this.state.aboType}
                    maxFiles={this.state.aboType === "IsFreeUser" ? 2 : 99}
                    deleteAdminComment={this.deleteAdminComment}
                  />
                </Form.Group>
                <Form.Group className="mt-5">
                  <div className="from-section-label">
                    <Translate id="model.videos" />
                    <span className="label-desc mt-1">
                      <Translate id="model.videosdesc" />
                    </span>
                  </div>
                  {this.state.aboType === "IsPremiumUser" ? (
                    <VideoUploadField
                      onChange={this.handleVideosStatusChange}
                      onDelete={this.handleVideosDelete}
                      files={this.state.videos}
                      alloweEdit={true}
                      adminCommentType="Video_"
                      adminComment={
                        this.state.modelStatus === "ApprovalPending"
                          ? this.state.adminComment
                          : {}
                      }
                      deleteAdminComment={this.deleteAdminComment}
                    />
                  ) : (
                    <>
                      <Link to="/upgrade">
                        <Translate id="model.aboUpgrade" />
                      </Link>
                      <UpgradeField />
                      <div className="my-5 border-bottom"></div>
                    </>
                  )}
                </Form.Group>
                {this.state.error ? (
                  <Alert variant="danger">{this.state.error}</Alert>
                ) : null}
                <Row>
                  {this.state.modelStatus !== "ApprovalPending" ? (
                    <Col
                      xs={12}
                      lg={this.state.modelStatus === "Registered" ? 6 : 12}
                      className="text-center"
                    >
                      <Button className="w-100" onClick={this.handleSubmit}>
                        <Translate id="model.saveData" />
                      </Button>
                    </Col>
                  ) : null}
                  {this.state.modelStatus === "Registered" ? (
                    <Col xs={12} lg={6} className="text-center">
                      <Button className="w-100" onClick={this.sendApprovalMail}>
                        <Translate id="model.requestApproval" />
                      </Button>
                    </Col>
                  ) : null}
                  {this.state.modelStatus === "ApprovalPending" ? (
                    <Col xs={12} lg={12} className="text-center mt-3">
                      <Button
                        className="w-100"
                        onClick={this.sendApprovalMail}
                        // disabled={Object.keys(this.state.adminComment).length <= 0}
                      >
                        <Translate id="model.requestApprovalAgain" />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            )}
          </Row>
        </section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.user,
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  translate: getTranslate(state.localize),
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
  fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditModelProfile));
