import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getTranslate, Translate } from "react-localize-redux";
import UserActions from "../../stores/user/actions";
import LocaleActions from "../../stores/locale/actions";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import UpgradeCard from "../../components/UpgradeCard/UpgradeCard";
import Payment from "../Payment/Payment";
import "./UpgradePage.style.scss";

class UpgradePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboType: "",
    };
  }

  componentDidMount() {
    this.props.fetchUser();
    this.setState({
      memberID: this.props.memberID,
      loading: false,
    });
  }

  render() {
    return (
      <Container>
        <section className="py-4">
          {this.state.loading ? (
            <Spinner
              animation="border"
              role="status"
              className="mx-auto my-3 d-block align-self-center"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <Container>
              <Row>
                <Col>
                  <h1 className="my-3 pb-5 text-center">
                    <Translate id="upgrade.title" />
                  </h1>
                </Col>
              </Row>
              <Row className="d-flex pb-5 justify-content-center">
                <Col className="d-flex flexHolder" lg={6} xs={12}>
                  <UpgradeCard isPremium={false} />
                </Col>
                <Col lg={6} xs={12}>
                  <UpgradeCard isPremium={true} />
                </Col>
              </Row>
              <Row className="pt-5">
                <Col>
                  <Payment />
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.user,
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  userIsLoading: state.user.userIsLoading,
  userErrorMessage: state.user.userErrorMessage,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
  fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpgradePage));
