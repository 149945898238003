import React, { Component } from "react";
import { getTranslate, Translate } from "react-localize-redux";
import { ApiService } from "../../services/ApiService";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import LazyImage from "../../components/LazyImage/LazyImage";
import "./ModelSearch.style.scss";
import Icofont from "../../components/Icofont/Icofont";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ModelSearchActions from "../../stores/modelSearch/actions";
import { toastr } from "react-redux-toastr";
import ChatButton from "../../components/ChatButton/ChatButton";
import ModelSwipeContainer from "./ModelSwipeContainer";
import ModelFilter from "../../components/ModelFilter/ModelFilter";
import { UnitConvertService } from "../../services/UnitConvertionService";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { Swipe } from "react-swipe-component";

let CancelToken = axios.CancelToken;
let cancel;

class ModelSearch extends Component {
  state = {
    address: {},
    bookingCode: "",
    clothingSizesForDropdown: UnitConvertService.clothingSizeAll,
    models: this.props.models ?? [],
    error: null,
    loading: true,
    position: this.props.position ?? 0,
    offset: this.props.offset ?? 0,
    limit: 12,
    showMoreModelButton: true,
    buttonloading: false,
    noResult: false,
    showCard: true,
    rangeFilter: "",
    rangeFilterVal: "",
    rangeFilterType: "km",
    genderFilter: "",
    skinColorFilter: "",
    hairColorFilter: "",
    eyeColorFilter: "",
    skinTypeFilter: "",
    heightFilter: [40, 220],
    heightFilterSI: [40, 220],
    heightType: "cm",
    weightFilter: [3, 150],
    weightFilterSI: [3, 150],
    weightType: "kg",
    priceFilter: [0, 1000],
    priceType: "EUR",
    ageFilter: [0, 99],
    shoeSizeFilter: [15, 50],
    shoeSizeFilterSI: [15, 50],
    shoeSizeType: "EU",
    clothingSizeFilter: [31, 177],
    clothingSizesType: "EU",
    filterOpen: false,
    initSlider: false,
    direction: 1,
    outDirection: 1,
    showSlider: this.props.showSlider ?? true,
    vh: 0,
    vw: 0,
    filterCount: 0,
    modelImageSize: 150,
  };

  constructor(props) {
    super(props);
    this.nextModel = this.nextModel.bind(this);
    this.prevModel = this.prevModel.bind(this);
    this.fetchModels = this.fetchModels.bind(this);
    this.handleProfileClick = this.handleProfileClick.bind(this);
    this.toggleModelFavourites = this.toggleModelFavourites.bind(this);
    this.toggleModelsView = this.toggleModelsView.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.getParams = this.getParams.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.setPropState = this.setPropState.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  onSwipeLeftListener = () => {
    this.nextModel();
  };
  onSwipeRightListener = () => {
    this.prevModel();
  };

  onSwipeUpListener = () => {
    window.scrollTo({ top: window.pageYOffset + 300, behavior: "smooth" });
  };
  onSwipeDownListener = () => {
    window.scrollTo({ top: window.pageYOffset - 300, behavior: "smooth" });
  };

  fetchModels(noLoading = false) {
    if (!this.state.loading && !noLoading) {
      this.setState({
        loading: true,
      });
    }
    let params = this.getParams();
    ApiService.authorizedClient(this.props.authKey)
      .get("/TopModel", {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then((r) => {
        let models = [...this.state.models];
        let noResults = false;
        if (this.state.offset === 0) {
          models = [];
        }
        let showMoreModelButton = true;
        if (r.data.length) {
          r.data.map((model) => {
            models.push(model);
          });
        } else {
          if (this.state.offset == 0) {
            noResults = true;
          }
          showMoreModelButton = false;
        }

        this.setState(
          {
            models: models,
            error: null,
            noResult: noResults,
            loading: false,
            showMoreModelButton: showMoreModelButton,
            buttonloading: false,
          },
          () => {
            this.props.setModels(this.state.models);
            this.props.setPosition(this.state.position);
            this.props.setOffset(this.state.offset);
          }
        );
      })
      .catch((error) => {
        if (
          typeof error.message !== "undefined" &&
          error.message === "NEWREQUEST"
        ) {
        } else {
          let errorMsg = this.props.translate("error.unknown");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          }
          this.setState({
            error: errorMsg,
            loading: false,
          });
        }
      });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    if (this.props.memberID) {
      ApiService.authorizedClient(this.props.authKey)
        .get("/Customer/" + this.props.memberID)
        .then((result) => {
          if (typeof result.data.Filters !== "undefined") {
            this.setState(JSON.parse(result.data.Filters), () => {
              this.loadFilteredModel();
              this.checkFilterSet();
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (cancel != undefined) {
        cancel("NEWREQUEST");
      }
      this.loadFilteredModel();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  clearFilter(fetch) {
    let fetchData = false;
    if (typeof fetch === "boolean") {
      fetchData = fetch;
    }
    this.setState(
      {
        address: {},
        bookingCode: "",
        rangeFilter: "",
        rangeFilterVal: "",
        rangeFilterType: "km",
        genderFilter: "",
        skinColorFilter: "",
        hairColorFilter: "",
        eyeColorFilter: "",
        skinTypeFilter: "",
        heightFilter: [40, 220],
        heightFilterSI: [40, 220],
        heightType: "cm",
        weightFilter: [3, 150],
        weightFilterSI: [3, 150],
        weightType: "kg",
        priceFilter: [0, 1000],
        priceType: "EUR",
        ageFilter: [0, 99],
        shoeSizeFilter: [15, 50],
        shoeSizeFilterSI: [15, 50],
        shoeSizeType: "EU",
        clothingSizesType: "EU",
        clothingSizeFilter: [31, 177],
        prevY: 0,
        filterCount: 0,
      },
      () => {
        if (fetchData) {
          this.fetchModels();
        }
      }
    );
  }

  checkFilterSet = () => {
    let counter = 0;
    if (typeof this.state.address.Title !== "undefined") {
      counter++;
    }
    if (this.state.bookingCode) {
      counter++;
    }
    if (this.state.genderFilter) {
      counter++;
    }
    if (this.state.skinColorFilter) {
      counter++;
    }
    if (this.state.hairColorFilter) {
      counter++;
    }
    if (this.state.eyeColorFilter) {
      counter++;
    }
    if (this.state.skinTypeFilter) {
      counter++;
    }
    if (this.state.rangeFilter) {
      counter++;
    }
    if (
      JSON.stringify(this.state.heightFilterSI) !== JSON.stringify([40, 220])
    ) {
      counter++;
    }
    if (
      JSON.stringify(this.state.weightFilterSI) !== JSON.stringify([3, 150])
    ) {
      counter++;
    }

    if (JSON.stringify(this.state.priceFilter) !== JSON.stringify([0, 1000])) {
      counter++;
    }
    if (JSON.stringify(this.state.ageFilter) !== JSON.stringify([0, 99])) {
      counter++;
    }
    if (
      JSON.stringify(this.state.shoeSizeFilterSI) !== JSON.stringify([15, 50])
    ) {
      counter++;
    }
    if (
      JSON.stringify(this.state.clothingSizeFilter) !==
      JSON.stringify([31, 177])
    ) {
      counter++;
    }
    this.setState({
      filterCount: counter,
    });
  };

  loadFilteredModel() {
    if (!this.state.models.length) {
      this.fetchModels();
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.modelImageSize !== this.state.modelImageSize) {
      this.updateDimensions();
    }

    if (prevState.offset !== this.state.offset && this.state.offset !== 0) {
      if (cancel != undefined) {
        cancel("NEWREQUEST");
      }
      this.fetchModels(true);
    }

    if (
      typeof this.state.models[this.state.position] === "undefined" &&
      this.state.error === null
    ) {
      if (this.state.position === 0) {
        // if (!this.state.noResult && !this.state.models.length) {
        //     this.setState({noResult: true, loading: false});
        // }
      } else {
        if (!this.state.loading) {
          if (cancel != undefined) {
            cancel("NEWREQUEST");
          }
          this.fetchModels();
        }
      }
    }
    if (prevState.clothingSizesType !== this.state.clothingSizesType) {
      if (this.state.clothingSizesType !== "EU") {
        this.setState({
          clothingSizesForDropdown: UnitConvertService.clothingSizeOther,
        });
      } else {
        this.setState({
          clothingSizesForDropdown: UnitConvertService.clothingSizeAll,
        });
      }
    }
  }

  checkNextModelState() {
    return this.state.models.length !== this.state.position + 1;
  }

  nextModel() {
    if (this.state.models.length === this.state.position + 1) {
      return;
    }

    let offset = this.state.offset;
    if (this.state.position === this.state.offset + 6) {
      offset = this.state.offset + 12;
    }
    this.setState(
      {
        position: this.state.position + 1,
        direction: 1,
        outDirection: 1,
        showCard: false,
        offset: offset,
      },
      () => {
        this.props.setPosition(this.state.position);
        setTimeout(() => {
          this.setState({ showCard: true });
        }, 400);
      }
    );
  }

  prevModel() {
    console.log(this.state.models.length);

    this.setState(
      {
        position: this.state.position - 1,
        showCard: false,
        direction: -1,
        outDirection: -1,
      },
      () => {
        this.props.setPosition(this.state.position);
        setTimeout(() => {
          this.setState({ showCard: true });
        }, 400);
      }
    );
  }

  getModel() {
    return this.state.models[this.state.position];
  }

  getNextModel() {
    return this.state.models[this.state.position + 1];
  }

  getPrevModel() {
    return this.state.models[this.state.position - 1];
  }

  handleProfileClick() {
    if (typeof this.getModel() !== "undefined") {
      this.props.history.push("/model/" + this.getModel().ID);
    }
  }

  toggleModelFavourites(modelID) {
    ApiService.authorizedClient(this.props.authKey)
      .post("/TopModelList/toggleModel/", { ModelID: modelID })
      .then((r) => {
        if (typeof r.data !== "undefined") {
          let models = [];
          let add = true;
          this.state.models.map((model) => {
            if (model.ID === modelID) {
              model.Favourit = r.data.Favourit;
              add = r.data.Favourit;
            }
            models.push(model);
            return null;
          });
          if (add) {
            toastr.success(
              this.props.translate("toastr.success"),
              this.props.translate("toastr.modelAdded")
            );
          } else {
            toastr.success(
              this.props.translate("toastr.success"),
              this.props.translate("toastr.modelRemoved")
            );
          }
          this.setState({ models: models }, () =>
            this.props.setModels(this.state.models)
          );
        }
      })
      .catch((error) => {
        let errorMsg = this.props.translate("error.unknown");
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMsg = error.response.data.message;
        }
        this.setState({
          error: errorMsg,
          loading: false,
        });
      });
  }

  toggleModelsView() {
    this.setState(
      {
        loading: true,
        showSlider: !this.state.showSlider,
      },
      () => {
        this.props.setShowSlider(this.state.showSlider);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 400);
      }
    );
  }

  applyFilter() {
    let price0 = parseInt(this.state.priceFilter[0]);
    let price1 = parseInt(this.state.priceFilter[1]);
    if (isNaN(this.state.priceFilter[0])) {
      price0 = 0;
    }
    if (price1 < price0) {
      price1 = price0;
    }
    let prices = [price0, price1];
    ApiService.authorizedClient(this.props.authKey)
      .put("/Customer/" + this.props.memberID, {
        Filters: JSON.stringify({
          address: this.state.address,
          bookingCode: this.state.bookingCode,
          rangeFilter: this.state.rangeFilter,
          rangeFilterVal: this.state.rangeFilterVal,
          rangeFilterType: this.state.rangeFilterType,
          genderFilter: this.state.genderFilter,
          skinColorFilter: this.state.skinColorFilter,
          hairColorFilter: this.state.hairColorFilter,
          eyeColorFilter: this.state.eyeColorFilter,
          skinTypeFilter: this.state.skinTypeFilter,
          heightFilter: [
            this.state.heightFilter[0],
            this.state.heightFilter[1],
          ],
          heightFilterSI: [
            this.state.heightFilterSI[0],
            this.state.heightFilterSI[1],
          ],
          heightType: this.state.heightType,
          weightFilter: [
            this.state.weightFilter[0],
            this.state.weightFilter[1],
          ],
          weightFilterSI: [
            this.state.weightFilterSI[0],
            this.state.weightFilterSI[1],
          ],
          weightType: this.state.weightType,
          priceFilter: prices,
          priceType: this.state.priceType,
          ageFilter: [this.state.ageFilter[0], this.state.ageFilter[1]],
          shoeSizeFilter: [
            this.state.shoeSizeFilter[0],
            this.state.shoeSizeFilter[1],
          ],
          shoeSizeFilterSI: [
            this.state.shoeSizeFilterSI[0],
            this.state.shoeSizeFilterSI[1],
          ],
          shoeSizeType: this.state.shoeSizeType,
          clothingSizesType: this.state.clothingSizesType,
          clothingSizeFilter: [
            this.state.clothingSizeFilter[0],
            this.state.clothingSizeFilter[1],
          ],
        }),
      })
      .then((result) => console.log(result))
      .catch((err) => console.log(err));
    this.setState(
      {
        filterOpen: !this.state.filterOpen,
        priceFilter: prices,
        offset: 0,
        position: 0,
      },
      () => this.fetchModels()
    );
  }

  setAddress(address) {
    this.setState({ address: address }, this.checkFilterSet);
  }

  getParams() {
    if (this.state.bookingCode) {
      return {
        sort: "Rating DESC,BookingCode ASC",
        "filter[Status]": "Active",
        "filter[BookingCode:PartialMatch]": this.state.bookingCode,
        cLimit: this.state.limit,
        cOffset: this.state.offset,
      };
    }
    let params = {
      sort: "Rating DESC,BookingCode ASC",
      "filter[Status]": "Active",
      cLimit: this.state.limit,
      cOffset: this.state.offset,
    };

    if (
      typeof this.state.address.Latitude !== "undefined" &&
      typeof this.state.address.Longitude !== "undefined"
    ) {
      params["lat"] = this.state.address.Latitude;
      params["lng"] = this.state.address.Longitude;
      if (this.state.rangeFilter) {
        params["distance"] = this.state.rangeFilter;
      }
    }
    if (this.state.genderFilter) {
      params["filter[Gender]"] = this.state.genderFilter;
    }
    if (this.state.ageFilter[0] !== 0) {
      let date = new Date();
      date.setFullYear(date.getFullYear() - this.state.ageFilter[0]);
      params["filter[Birthdate:LessThanOrEqual]"] =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

    if (this.state.ageFilter[1] !== 99) {
      let date = new Date();
      date.setFullYear(date.getFullYear() - this.state.ageFilter[1]);
      params["filter[Birthdate:GreaterThanOrEqual]"] =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

    if (this.state.heightFilterSI[0] !== 40) {
      params["filter[Height:GreaterThanOrEqual]"] =
        this.state.heightFilterSI[0];
    }

    if (this.state.heightFilterSI[1] !== 250) {
      params["filter[Height:LessThanOrEqual]"] = this.state.heightFilterSI[1];
    }

    if (this.state.weightFilterSI[0] !== 3) {
      params["filter[Weight:GreaterThanOrEqual]"] =
        this.state.weightFilterSI[0];
    }

    if (this.state.weightFilterSI[1] !== 150) {
      params["filter[Weight:LessThanOrEqual]"] = this.state.weightFilterSI[1];
    }

    if (this.state.priceFilter[0] !== 0) {
      params["priceFrom"] = this.state.priceFilter[0];
    }

    if (this.state.priceFilter[1] !== 1000) {
      params["priceTo"] = this.state.priceFilter[1];
    }

    params["priceType"] = this.state.priceType;

    if (this.state.clothingSizeFilter[0] !== 31) {
      params["filter[ClothingSize:GreaterThanOrEqual]"] =
        this.state.clothingSizeFilter[0];
    }

    if (this.state.clothingSizeFilter[1] !== 177) {
      params["filter[ClothingSize:LessThanOrEqual]"] =
        this.state.clothingSizeFilter[1];
    }

    if (this.state.shoeSizeFilterSI[0] !== 15) {
      params["filter[ShoeSize:GreaterThanOrEqual]"] =
        this.state.shoeSizeFilterSI[0];
    }

    if (this.state.shoeSizeFilterSI[1] !== 50) {
      params["filter[ShoeSize:LessThanOrEqual]"] =
        this.state.shoeSizeFilterSI[1];
    }

    if (this.state.hairColorFilter) {
      params["filter[HairColor]"] = this.state.hairColorFilter;
    }

    if (this.state.eyeColorFilter) {
      params["filter[EyeColor]"] = this.state.eyeColorFilter;
    }

    if (this.state.skinTypeFilter) {
      params["filter[SkinType]"] = this.state.skinTypeFilter;
    }

    if (this.state.skinColorFilter) {
      params["filter[SkinColor]"] = this.state.skinColorFilter;
    }

    return params;
  }

  updateDimensions() {
    if (typeof this.resizeTimeout === "number") {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      const vw = document.documentElement.clientWidth;
      const vh = document.documentElement.clientHeight;

      let modelImageSize = vw * 0.8 > vh * 0.7 ? vh * 0.7 : vw * 0.8;

      let minSize = vw * 0.33;
      if (modelImageSize < minSize) {
        modelImageSize = minSize;
      }

      this.setState({
        vh: vh,
        vw: vw,
        modelImageSize: modelImageSize,
      });
    }, 100);
  }

  setPropState(state) {
    this.setState(state, () => {
      this.checkFilterSet();
    });
  }

  render() {
    return (
      <>
        <Container fluid className="p-0">
          <div className="col-12 p-0 pt-3">
            <Accordion>
              <div
                className="d-flex flex-column justify-content-center align-items-center toggle-style"
                onClick={this.toggleModelsView}
              >
                {this.state.showSlider ? (
                  <Icofont icon="listing-box" size={2} />
                ) : (
                  <Icofont icon="penalty-card" size={2} />
                )}
                <span>
                  <Translate id="modelSearch.switchStyle" />
                </span>
              </div>
              {this.state.filterCount ? (
                <div className="toggle-clearFilter-holder">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center toggle-clearFilter"
                    onClick={() => {
                      this.clearFilter(true);
                    }}
                  >
                    <Icofont icon="close" size={2} />
                    <span>
                      <Translate id="modelSearch.clearFilter" /> (
                      {this.state.filterCount})
                    </span>
                  </div>
                </div>
              ) : null}
              <Card>
                <Card.Header className={this.state.filterOpen ? "active" : ""}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="w-100"
                    onClick={() =>
                      this.setState({
                        filterOpen: !this.state.filterOpen,
                        initSlider: true,
                      })
                    }
                  >
                    <Translate id="modelSearch.filterSettings" />
                    <div className="click-here-text">
                      <Translate id="modelSearch.clickHere" />
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row className="filter-holder">
                      <Col
                        xs={12}
                        className="text-center py-2 py-lg-3 mt-4 text-white"
                      >
                        <h4>
                          <Translate id="modelSearch.filterHeading" />
                        </h4>
                      </Col>
                      <ModelFilter
                        {...this.state}
                        idCounter={0}
                        setAddress={this.setAddress}
                        setPropState={this.setPropState}
                        clearFilter={this.clearFilter}
                      />
                      <Col lg={6} className="d-flex justify-content-center p-0">
                        <div
                          variant="secondary"
                          className="clear-filter w-100 d-flex flex-column align-items-center"
                          onClick={this.clearFilter}
                        >
                          <Icofont icon="close" className="mr-2" size={2} />
                          <Translate id="modelSearch.clearFilter" />
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-center p-0">
                        <Accordion.Toggle
                          eventKey="0"
                          className="applyFilter-btn d-flex flex-column align-items-center w-100"
                          onClick={this.applyFilter}
                        >
                          <Icofont icon="check-circled" size={2} />
                          <div>
                            <Translate id="modelSearch.applyFiler" />
                          </div>
                        </Accordion.Toggle>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </Container>
        <Container
          fluid
          className={
            "overflow-hidden " + (this.state.showSlider ? "px-15" : "")
          }
        >
          <div className="row justify-content-center">
            <div className="col-12 overflow-hidden">
              {this.state.error !== null ? (
                <p className="text-danger text-center">{this.state.error}</p>
              ) : null}
              {this.state.noResult && !this.state.loading ? (
                <p className="text-center py-5">
                  <Translate id="modelSearch.noResult" />
                </p>
              ) : null}
            </div>
            {this.state.showSlider ? (
              <>
                <div
                  dir="ltr"
                  className="col-12 p-0"
                  style={{ height: this.state.modelImageSize }}
                >
                  {this.state.loading === false &&
                  this.state.noResult === false &&
                  this.state.error === null ? (
                    <div className="bg-black">
                      <Swipe
                        className="interface-overlay"
                        detectTouch={true}
                        onSwipedLeft={this.onSwipeLeftListener}
                        onSwipedRight={this.onSwipeRightListener}
                        onSwipedUp={this.onSwipeUpListener}
                        onSwipedDown={this.onSwipeDownListener}
                      >
                        <div className="prev-model overflow-hidden">
                          <div className="model-switch-button-container">
                            {typeof this.state.models[
                              this.state.position - 1
                            ] !== "undefined" ? (
                              <div
                                className="model-switch-button d-flex align-items-center"
                                onClick={this.prevModel}
                              >
                                <Icofont
                                  icon="arrow-left"
                                  className="mr-lg-2"
                                  size={2}
                                />
                                <span className="d-none d-lg-block">
                                  <Translate id="modelSearch.prev" />
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="current-model"
                          onClick={this.handleProfileClick}
                        >
                          <div
                            className="model-card"
                            style={{
                              width: this.state.modelImageSize,
                              height: this.state.modelImageSize,
                            }}
                          >
                            {typeof this.state.models[this.state.position] !==
                            "undefined" ? (
                              <>
                                <div className="info">
                                  <h1 className="text-white">
                                    {
                                      this.state.models[this.state.position]
                                        .FirstName
                                    }
                                  </h1>
                                  <p className="text-white">
                                    {
                                      this.state.models[this.state.position]
                                        .AddressTitle
                                    }
                                  </p>
                                  <p className="text-white">
                                    {
                                      this.state.models[this.state.position]
                                        .BookingCode
                                    }
                                  </p>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="next-model overflow-hidden">
                          <div className="model-switch-button-container">
                            {/* TODO vllt. hier der Fix für die Suche? */}
                            {/*(this.state.bookingCode ||
                              this.state.models.length === 1) &&*/}
                            {this.state.models.length ===
                            this.state.position + 1 ? null : (
                              <div
                                className="model-switch-button d-flex align-items-center"
                                onClick={this.nextModel}
                              >
                                <span className="d-none d-lg-block">
                                  <Translate id="modelSearch.next" />
                                </span>
                                <Icofont
                                  icon="arrow-right"
                                  className="ml-lg-2"
                                  size={2}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Swipe>
                      <ModelSwipeContainer
                        models={[{}, {}, ...this.state.models]}
                        position={this.state.position}
                        sizing={{
                          vh: this.state.vh,
                          vw: this.state.vw,
                          modelImageSize: this.state.modelImageSize,
                        }}
                      />
                    </div>
                  ) : this.state.loading ? (
                    <div className="py-5 w-100 text-center">
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto my-5d-block"
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : null}
                </div>
                <div
                  className="model-actions d-flex flex-wrap justify-content-center py-3"
                  id="scrollswipe"
                >
                  <div
                    className="model-action d-flex flex-column justify-content-center"
                    onClick={this.handleProfileClick}
                  >
                    <div className="text-center">
                      <Icofont icon="search-job" size={2} />
                    </div>
                    <div className="action-title">
                      <Translate id="modelSearch.showProfile" />
                    </div>
                  </div>
                  <div className="model-action d-flex flex-column justify-content-center">
                    {typeof this.state.models[this.state.position] !==
                    "undefined" ? (
                      <ChatButton
                        modelID={this.state.models[this.state.position].ID}
                        modelBookingCode={
                          this.state.models[this.state.position].BookingCode
                        }
                        fallbackCall={() =>
                          this.props.history.push(
                            "/chats/new/" +
                              this.state.models[this.state.position].BookingCode
                          )
                        }
                      >
                        <div className="text-center">
                          <Icofont icon="ui-text-chat" size={2} />
                        </div>
                        <div className="action-title">
                          <Translate id="modelSearch.startChat" />
                        </div>
                      </ChatButton>
                    ) : null}
                  </div>
                  <div
                    className={
                      "model-action d-flex flex-column justify-content-center " +
                      (typeof this.state.models[this.state.position] !==
                        "undefined" &&
                      this.state.models[this.state.position].Favourit
                        ? "active-favorit"
                        : "")
                    }
                    onClick={() =>
                      this.toggleModelFavourites(
                        this.state.models[this.state.position].ID
                      )
                    }
                  >
                    {typeof this.state.models[this.state.position] !==
                      "undefined" &&
                    this.state.models[this.state.position].Favourit ? (
                      <>
                        <div className="text-center text-danger">
                          <Icofont icon="ui-rate-remove" size={2} />
                        </div>
                        <div className="action-title text-danger">
                          <Translate id="modelSearch.remove" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <Icofont icon="favourite" size={2} />
                        </div>
                        <div className="action-title">
                          <Translate id="modelSearch.addToFavourites" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="model-teaser-holder p-3 w-100 min-vh-100">
                {this.state.loading === false &&
                this.state.noResult === false &&
                this.state.error === null ? (
                  <InfiniteScroll
                    className="row"
                    dataLength={this.state.models.length} //This is important field to render the next data
                    next={() => {
                      this.setState({
                        offset: this.state.offset + 12,
                        buttonloading: true,
                      });
                    }}
                    hasMore={
                      this.state.showMoreModelButton &&
                      this.state.models.length >= 12 &&
                      !this.state.loading
                    }
                    loader={
                      <div className="py-5 w-100 text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          className="mx-auto my-5d-block"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    }
                  >
                    {this.state.models.map((model, index) => {
                      return (
                        <Col lg={3} key={model.ID}>
                          <div className="model-teaser">
                            <div className="model-content position-relative">
                              <Link to={"/model/" + model.ID}>
                                <LazyImage src={model.PreviewImage} fluid />
                              </Link>
                            </div>
                            <div className="model-teaser-info-inner">
                              <div className="info">
                                <p className=" model-title">
                                  {model.FirstName}
                                </p>
                                <p className=" booking-code">
                                  <b>{model.BookingCode}</b>
                                </p>
                                <p className=" model-city mt-2">
                                  {model.AddressTitle}
                                </p>
                              </div>
                              <div className="model-teaser-actions d-flex justify-content-between">
                                <Link
                                  className="model-action d-flex flex-column justify-content-center"
                                  to={"/model/" + model.ID}
                                >
                                  <div className="text-center">
                                    <Icofont icon="search-job" />
                                  </div>
                                  <div className="action-title">
                                    <Translate id="modelSearch.showProfile" />
                                  </div>
                                </Link>
                                <div className="model-action d-flex flex-column justify-content-center">
                                  <ChatButton
                                    modelID={model.ID}
                                    modelBookingCode={model.BookingCode}
                                    fallbackCall={() =>
                                      this.props.history.push(
                                        "/chats/new/" + model.BookingCode
                                      )
                                    }
                                  >
                                    <div className="text-center">
                                      <Icofont icon="ui-text-chat" />
                                    </div>
                                    <div className="action-title">
                                      <Translate id="modelSearch.startChat" />
                                    </div>
                                  </ChatButton>
                                </div>
                                <div
                                  className={
                                    "model-action d-flex flex-column justify-content-center " +
                                    (model.Favourit ? "active-favorit" : "")
                                  }
                                  onClick={() =>
                                    this.toggleModelFavourites(model.ID)
                                  }
                                >
                                  {model.Favourit ? (
                                    <>
                                      <div className="text-center text-danger">
                                        <Icofont icon="ui-rate-remove" />
                                      </div>
                                      <div className="action-title text-danger">
                                        <Translate id="modelSearch.remove" />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="text-center">
                                        <Icofont icon="favourite" />
                                      </div>
                                      <div className="action-title">
                                        <Translate id="modelSearch.addToFavourites" />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div className="py-5 w-100 text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      className="mx-auto my-5d-block"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </div>
            )}
          </div>
        </Container>
        {this.state.showSlider ? (
          <>
            <Container fluid className="bg-primary filter-holder pt-5">
              <Row>
                <Col lg={12}>
                  <h3 className="text-white text-center pb-4">
                    <Translate id="modelSearch.filterSettings" />
                  </h3>
                </Col>
                <ModelFilter
                  {...this.state}
                  initSlider={true}
                  idCounter={1}
                  setAddress={this.setAddress}
                  setPropState={this.setPropState}
                  clearFilter={this.clearFilter}
                />
              </Row>
            </Container>
            <Container fluid className="py-0 filter-holder bg-white">
              <Row>
                <Col lg={6} className="d-flex justify-content-center p-0">
                  <div
                    variant="secondary"
                    className="clear-filter w-100 d-flex flex-column align-items-center"
                    onClick={this.clearFilter}
                  >
                    <Icofont icon="close" className="mr-2" size={2} />
                    <Translate id="modelSearch.clearFilter" />
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-center p-0">
                  <div
                    className="applyFilter-btn d-flex flex-column align-items-center w-100"
                    onClick={this.applyFilter}
                  >
                    <Icofont icon="check-circled" size={2} />
                    <div>
                      <Translate id="modelSearch.applyFiler" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authKey: state.user.authKey,
  memberID: state.user.memberID,
  models: state.modelSearch.models,
  position: state.modelSearch.position,
  showSlider: state.modelSearch.showSlider,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => ({
  setModels: (models) => dispatch(ModelSearchActions.setModels(models)),
  setPosition: (position) => dispatch(ModelSearchActions.setPosition(position)),
  setOffset: (offset) => dispatch(ModelSearchActions.setOffset(offset)),
  setShowSlider: (mode) => dispatch(ModelSearchActions.setShowSlider(mode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelSearch));
