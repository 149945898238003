import React, { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "../../assets/translations";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import LocaleActions from "../../stores/locale/actions";
import axios from "axios";

const langs = [
  { name: "DE", fullName: "Deutsch", code: "de_DE", alpha2: "de" },
  // {name: "EN", fullName: "English", code: "en_GB", alpha2: 'en'},
  // {name: "EN", fullName: "English (US)", code: "en_US", alpha2: 'en'},
  // {name: "ES", fullName: "Español", code: "es_ES", alpha2: 'es'},
  // {name: "FR", fullName: "Français", code: "fr_FR", alpha2: 'fr'},
  // {name: "AR", fullName: "Arabic (عربى)", code: "ar_AE", alpha2: 'ar'},
  // {name: "ZH", fullName: "China (中國人)", code: "zh_CN", alpha2: 'zh'},
  // {name: "IT", fullName: "Italiano", code: "it_IT", alpha2: 'it'},
  // {name: "RU", fullName: "Russian (русский)", code: "ru_RU", alpha2: 'ru'},
  // {name: "TR", fullName: "Turkish (Türkçe)", code: "tr_TR", alpha2: 'tr'},
  // {name: "SE", fullName: "Swedish (Svenska)", code: "sv_SE", alpha2: 'se'},
  // {name: "PT", fullName: "Portuguese (Português)", code: "pt_PT", alpha2: 'pt'},
];

// const langs = [
//     {name: "DE", fullName: "Deutsch", code: "de_DE", alpha2: 'de'}
// ];

class LocalizeConsumer extends Component {
  constructor(props) {
    super(props);
    let defaultLocale = "de_DE";

    this.props.initialize({
      languages: langs,
      options: {
        defaultLanguage: defaultLocale,
        renderToStaticMarkup: renderToStaticMarkup,
      },
    });
    Object.keys(translations).map((key) => {
      this.props.addTranslationForLanguage(translations[key], key);
      return null;
    });

    this.state = {
      ipLocale: null,
      forceLocale: null,
    };
  }

  componentDidMount() {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const locale = urlParams.get("locale");
    let timeout = 0;
    if (locale) {
      timeout = 500;
    }
    setTimeout(() => {
      this.getDefaultLocal();
    }, timeout);
  }

  getDefaultLocal() {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const locale = urlParams.get("locale");
    if (locale) {
      let langCode = locale.split("_")[0];
      let result = langs.find((elem) => elem.alpha2 === langCode);
      if (result) {
        this.setState({
          ipLocale: result.code,
          forceLocale: result.code,
        });
      }
    } else {
      console.log("BROWSER");
      let lang = navigator.language;
      let langCode = lang.split("-")[0];
      let result = langs.find((elem) => elem.alpha2 === langCode);
      if (result) {
        this.setState({
          ipLocale: result.code,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.forceLocale !== prevState.forceLocale) {
      this.props.setLocale(this.state.forceLocale);
      this.props.setActiveLanguage(this.state.forceLocale);
      this.props.setSetByUser(true);
    } else {
      if (
        this.props.locale !== null &&
        typeof this.props.activeLanguage !== "undefined" &&
        this.props.locale !== this.props.activeLanguage.code
      ) {
        this.props.setActiveLanguage(this.props.locale);
      } else if (this.props.locale === null) {
        if (typeof this.props.user.Locale !== "undefined") {
          this.props.setLocale(this.props.user.Locale);
        } else if (this.state.ipLocale !== null) {
          this.props.setLocale(this.state.ipLocale);
        } else {
          this.props.setLocale("de_DE");
        }
      }

      if (
        typeof this.props.user.Locale !== "undefined" &&
        this.props.user.Locale !== prevProps.user.Locale
      ) {
        this.props.setLocale(this.props.user.Locale);
        this.props.setSetByUser(true);
      } else if (
        this.state.ipLocale !== null &&
        this.props.locale !== this.state.ipLocale &&
        typeof this.props.activeLanguage !== "undefined" &&
        this.state.ipLocale !== this.props.activeLanguage.code
      ) {
        if (!this.props.localeSetByUser) {
          this.props.setLocale(this.state.ipLocale);
          this.setState({ ipLocale: null });
        }
      }
    }

    if (prevProps.locale !== this.props.locale) {
      if (this.props.locale === "ar_AE") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        document.getElementsByTagName("body")[0].classList.add("rtl-content");
      } else {
        document.getElementsByTagName("html")[0].removeAttribute("dir");
        document
          .getElementsByTagName("body")[0]
          .classList.remove("rtl-content");
      }
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  memberID: state.user.memberID,
  user: state.user.user,
  locale: state.locale.locale,
  localeSetByUser: state.locale.setByUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLocale: (localeCode) => dispatch(LocaleActions.setLocale(localeCode)),
  setSetByUser: (val) => dispatch(LocaleActions.setSetByUser(val)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(LocalizeConsumer));
