import React, { Component } from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import { primary } from "../../theme/colors.scss";
// import Logo from "../../assets/img/logo_navi_topmodelcast.png";
import Logo from "../../assets/img/logo_newstar.png";
import BG from "../../assets/img/pattern_topmodelcast-min.png";
import { LocalizeProvider, Translate } from "react-localize-redux";
import LocalizeConsumer from "../LocalizeConsumer/LocalizeConsumer";
import createStore from "../../stores";
import pdfFont from "../../assets/pdffont/Amiri-Regular.ttf";
import pdfFontRU from "../../assets/pdffont/Roboto-Regular.ttf";
import pdfFontCN from "../../assets/pdffont/msyh.ttf";
import { Provider } from "react-redux";

// Register font
Font.register({ family: "PDFFont1", src: pdfFont });
Font.register({ family: "PDFFont2", src: pdfFontCN });
Font.register({ family: "PDFFont3", src: pdfFontRU });

// Create styles
const styles = StyleSheet.create({
  page_default: {
    fontFamily: "PDFFont1",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 10,
    backgroundImage: "url(" + BG + ")",
  },
  page_CN: {
    fontFamily: "PDFFont2",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 10,
    backgroundImage: "url(" + BG + ")",
  },
  page_RU: {
    fontFamily: "PDFFont3",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 10,
    backgroundImage: "url(" + BG + ")",
  },
  section: {
    margin: 0,
    padding: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    flexBasis: "100%",
  },
  sectionBottom: {
    margin: 0,
    padding: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    flexBasis: "100%",
    minHeight: "400px",
  },
  sectionTop: {
    margin: 0,
    padding: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    flexBasis: "66%",
  },
  mainImageHolder: {
    flexBasis: "40%",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5,
  },
  imageBorder: {
    margin: 5,
    border: "1 solid #ab8527",
  },
  modelImage: {
    flexBasis: "100%",
    height: "100%",
  },
  modelImagePadding: {
    padding: 5,
    flexBasis: "100%",
    height: "100%",
  },
  modelImagePlaceHolder: {
    margin: 5,
    height: "140px",
    width: "140px",
    border: "1 solid #ab8527",
  },
  modelData: {
    flexBasis: "60%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    flexGrow: 1,
  },
  dataContainer: {
    padding: 10,
    marginTop: 5,
    backgroundColor: primary,
    flexBasis: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  modelDataLeft: {
    flexBasis: "50%",
    flexDirection: "row",
    flexWrap: "wrap",
    textAlign: "left",
    color: "#fff",
    fontSize: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: 5,
  },
  modelDataRight: {
    flexBasis: "50%",
    flexDirection: "row",
    flexWrap: "wrap",
    textAlign: "right",
    color: "#fff",
    fontSize: 10,
    marginBottom: 5,
  },
});

const { store, persistor } = createStore();
export { store };

class SedCard extends Component {
  render() {
    let lenght = this.props.model.ModelImagesImage.length;
    let remaing = 10 - lenght;
    let modelImages = [];
    this.props.model.ModelImagesImage.map((image, index) => {
      modelImages.push(image);
    });
    for (let i = 0; i < remaing; i++) {
      modelImages.push({ ImageURL: "PLACEHOLDER" });
    }
    let pageTemp =
      this.props.locale !== "zh_CN" && this.props.model.Locale !== "zh_CN"
        ? styles.page_default
        : styles.page_CN;
    const pageStyle =
      this.props.locale !== "ru_RU" && this.props.model.Locale !== "ru_RU"
        ? pageTemp
        : styles.page_RU;
    return (
      <Provider store={store}>
        <LocalizeProvider>
          <LocalizeConsumer>
            <Document>
              <Page size="A4" orientation="landscape" style={pageStyle}>
                <View style={{ flexBasis: "100%", flexDirection: "row" }}>
                  <View style={{ flexBasis: "50%", paddingLeft: 10 }}>
                    <Image
                      src={Logo}
                      style={{ height: "30px", width: "123px" }}
                    />
                  </View>
                  <View
                    style={{
                      flexBasis: "50%",
                      textAlign: "right",
                      paddingRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 18,
                        color: primary,
                        marginTop: 10,
                      }}
                    >
                      {this.props.model.FirstName} //{" "}
                      {this.props.model.BookingCode}
                    </Text>
                  </View>
                </View>
                <View style={styles.sectionTop}>
                  <View style={styles.mainImageHolder}>
                    <Image
                      src={this.props.model.PreviewImage}
                      style={styles.modelImagePadding}
                    />
                  </View>
                  <View style={styles.modelData}>
                    <View
                      style={{
                        flexBasis: "60%",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          flexBasis: "100%",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {modelImages.map((image, index) => {
                          return index < 2 ? (
                            <View style={{ flexBasis: "50%" }} key={index}>
                              {image.ImageURL === "PLACEHOLDER" ? (
                                <View style={styles.modelImagePlaceHolder} />
                              ) : (
                                <View style={styles.imageBorder}>
                                  <Image
                                    src={image.ImageURL}
                                    style={styles.modelImage}
                                    fluid
                                  />
                                </View>
                              )}
                            </View>
                          ) : null;
                        })}
                      </View>
                      <View
                        style={{
                          flexBasis: "100%",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: 27,
                        }}
                      >
                        {modelImages.map((image, index) => {
                          return index > 1 && index < 4 ? (
                            <View style={{ flexBasis: "50%" }} key={index}>
                              {image.ImageURL === "PLACEHOLDER" ? (
                                <View style={styles.modelImagePlaceHolder} />
                              ) : (
                                <View style={styles.imageBorder}>
                                  <Image
                                    src={image.ImageURL}
                                    style={styles.modelImage}
                                    fluid
                                  />
                                </View>
                              )}
                            </View>
                          ) : null;
                        })}
                      </View>
                    </View>
                    <View
                      style={{
                        flexBasis: "40%",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        paddingLeft: 10,
                        paddingBottom: 5,
                      }}
                    >
                      <View style={styles.dataContainer}>
                        <View
                          style={{
                            flexBasis: "100%",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <Text style={styles.modelDataLeft}>
                            <Translate
                              id="model.age"
                              options={{ language: this.props.locale }}
                            />
                          </Text>
                          <Text style={styles.modelDataRight}>
                            {this.props.model.Age}
                          </Text>
                        </View>
                        {this.props.model.AddressTitle ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.city"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.AddressTitle}
                            </Text>
                          </View>
                        ) : null}

                        {this.props.model.Gender ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.gender"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              <Translate
                                id={"model.gender_" + this.props.model.Gender}
                              />
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.Height ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.height"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.Height} cm
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.ShoeSize ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.shoesize"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.ShoeSize}
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.Weight ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.weight"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.Weight} kg
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.ClothingSize ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.clothingSizeShort"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.ClothingSize}
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.Chest ||
                        this.props.model.Hip ||
                        this.props.model.Waist ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.bodyMeasurements"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              {this.props.model.Chest} {this.props.model.Hip}{" "}
                              {this.props.model.Waist}
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.HairColor ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.hair"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              <Translate
                                id={"color.c_" + this.props.model.HairColor}
                              />
                            </Text>
                          </View>
                        ) : null}
                        {this.props.model.EyeColor ? (
                          <View
                            style={{
                              flexBasis: "100%",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            <Text style={styles.modelDataLeft}>
                              <Translate
                                id="model.eyes"
                                options={{ language: this.props.locale }}
                              />
                            </Text>
                            <Text style={styles.modelDataRight}>
                              <Translate
                                id={"color.c_" + this.props.model.EyeColor}
                              />
                            </Text>
                          </View>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.section}>
                  {modelImages.length > 5 ? (
                    <View
                      style={{
                        flexBasis: "100%",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        padding: 5,
                      }}
                    >
                      {modelImages.map((image, index) => {
                        return index > 3 && index < 9 ? (
                          <View style={{ flexBasis: "20%" }} key={index}>
                            {image.ImageURL === "PLACEHOLDER" ? (
                              <View style={styles.modelImagePlaceHolder} />
                            ) : (
                              <View style={styles.imageBorder}>
                                <Image
                                  src={image.ImageURL}
                                  style={styles.modelImage}
                                  fluid
                                />
                              </View>
                            )}
                          </View>
                        ) : null;
                      })}
                    </View>
                  ) : (
                    <View
                      style={{
                        flexBasis: "100%",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        padding: 5,
                        minHeight: 170,
                      }}
                    ></View>
                  )}
                  <View
                    style={{
                      flexBasis: "98%",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      backgroundColor: "#000",
                      padding: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      fontSize: 12,
                    }}
                  >
                    <Text style={{ color: "#fff", textAlign: "center" }}>
                      TOPMODELCAST GmbH
                    </Text>
                  </View>
                </View>
              </Page>
            </Document>
          </LocalizeConsumer>
        </LocalizeProvider>
      </Provider>
    );
  }
}

export default SedCard;
