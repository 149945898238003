import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import { Button } from "react-bootstrap";

export default class UpgradeField extends Component {
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.handleAboUpgrade = this.handleAboUpgrade.bind(this);
  }

  handleAboUpgrade() {
      window.location.href = "/upgrade";
  }

  render() {
    return (
      <div className="text-center">
        <div className="border-bottom my-5" />
        <h4 className="mb-4">
          <Translate id="settings.getAbo" />
        </h4>
        <div className="mb-4">
          <Translate id="settings.aboExplain" />
        </div>
        <Button onClick={this.handleAboUpgrade} className="w-100">
          <Translate id="settings.getPremium" />
        </Button>
      </div>
    );
  }
}
